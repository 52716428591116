<template>
  <div class="News">
    <Navbar />
    <NewsMobile class="banner-mobile" />
    <div class="row banner">
      <div class="col-7 image-container">
        <img
          @click="showDetail(articles[0].id)"
          class="image-left"
          src="../assets/News/article10.jpeg"
        />
        <p class="image-text">
          {{ this.articles[0].date }} <br />
          {{ sliceContent(this.articles[0].content) }}
        </p>
      </div>
      <div class="col-5">
        <div class="image-container">
          <img
            @click="showDetail(articles[1].id)"
            class="image-right"
            src="../assets/News/article9.jpg"
          />
          <p class="image-text">
            {{ this.articles[1].date }}<br />
            {{ sliceContent(this.articles[1].content) }}
          </p>
        </div>
        <div class="image-container">
          <img
            @click="showDetail(articles[2].id)"
            f
            class="image-right bottom"
            src="../assets/News/article7.png"
          />
          <p class="image-text">
            {{ this.articles[2].date }}<br />
            {{ sliceContent(this.articles[2].content) }}
          </p>
        </div>
      </div>
    </div>
    <div class="news-content-main">
      <div class="title text-center">
        <span class="news-title-desktop">
          <img class="flower" src="../assets/Dashboard/flower.png" />
          <span class="news-title">News & Updates</span>
          <img class="flower" src="../assets/Dashboard/flower.png" />
        </span>
        <div
          class="row justify-content-center align-items-center news-title-mobile"
        >
          <img class="flower col-3" src="../assets/Dashboard/flower.png" />
          <div class="ready-title col-6 text-center">
            News & Update
          </div>
          <img class="flower col-3" src="../assets/Dashboard/flower.png" />
        </div>
      </div>
      <div class="news-content text-center">
        <div class="row news-content">
          <div
            v-for="(item, index) in articlesShown()"
            @click="showDetail(item.id)"
            v-bind:key="index"
            class="col-md-4 col-sm-12 col-xl-4 news"
          >
            <img
              class="news-image"
              :src="require('../assets/News/' + item.mainImage)"
            />
            <p class="date">{{ item.date }}</p>
            <h3 class="item-title">{{ item.title }}</h3>
            <p class="news-desc">
              {{ sliceContent(item.content) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import Navbar from '../components/Navbar.vue';
  import Footer from '../components/Footer.vue';
  import NewsMobile from '../components/NewsMobileBanner.vue';
  import Articles from '../pages/Articles/articles.json';

  export default {
    name: 'News',
    components: { Navbar, Footer, NewsMobile },
    data() {
      return {
        showed: false,
        articles: Articles,
      };
    },
    methods: {
      sliceContent(content) {
        const sliceStr = content.slice(0, 200) + '...';
        return sliceStr.replace(/<\/?[^>]+(>|$)/g, '');
      },
      articlesShown() {
        const cloneThis = [...this.articles];
        return cloneThis.splice(3, 7);
      },
      show(state) {
        if (state) {
          this.showed = state;
        } else {
          this.showed = false;
        }
      },
      showDetail(id) {
        this.$router.push({ path: `/news/${id}` });
      },
    },
  };
</script>

<style scoped>
  .News {
    font-family: Museo;
    color: #035795;
    overflow-x: hidden;
  }

  .middle {
    text-align: center;
  }

  h3 {
    font-weight: 800px;
  }

  .title {
    margin-top: 50px;
  }

  .news {
    /* margin: 20px; */
    /* margin-right: auto;
    margin-left: auto; */
    padding: 20px;
    margin-bottom: 70px;
  }

  .news-title {
    font-family: Museo;
    color: #035795;
    font-size: 40px;
  }

  .date {
    margin-top: 30px;
    font-size: 20px;
    color: grey;
  }

  .image-container {
    position: relative;
    padding: 0 !important;
  }
  .image-left {
    width: 100%;
    height: 810px;
    object-fit: cover;
  }

  .image-right {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  .bottom {
    margin-top: 10px;
  }

  .banner {
    margin-top: 2%;
  }

  .image-text {
    position: absolute;
    bottom: 10%;
    left: 8%;
    margin-right: 10%;
    color: white;
    /* left: 16px; */
  }

  .flower {
    margin: 0 20px 20px 20px;
    width: 40px;
  }

  .news-image {
    border-radius: 10px;
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  .news-content {
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    margin-bottom: 50px;
    text-align: left;
    max-width: 1100px;
  }

  .news-desc {
    margin-top: 30px;
  }

  .banner-mobile {
    display: none;
  }

  .see-more {
    margin-bottom: 50px;
    margin-top: 80px;
  }

  .news-content-main {
    margin-right: 30px;
    margin-left: 30px;
  }

  @media only screen and (min-width: 992px) {
    .news-title-mobile {
      display: none;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {
    .news-image {
      height: 30%;
    }

    .news-title-mobile {
      display: none;
    }
  }

  @media only screen and (min-width: 321px) and (max-width: 767px) {
    .news-image {
      height: 300px;
    }

    .banner-mobile {
      display: block !important;
    }

    .banner {
      display: none;
    }

    .news-title {
      font-size: 30px !important;
    }

    .news-title-desktop {
      display: none;
    }

    .flower {
      margin-top: 20px;
      width: 40px;
    }

    .ready-title {
      font-family: Museo-bold;
      font-size: 30px;
    }

    .news-content-main {
      margin-right: 20px;
      margin-left: 20px;
    }
    .row > * {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }

  @media only screen and (max-width: 320px) {
    .flower {
      margin-top: 20px;
      width: 30px;
    }

    .banner-mobile {
      display: block !important;
    }

    .banner {
      display: none;
    }
    .news-title-desktop {
      display: none;
    }

    .ready-title {
      font-size: 15px;
    }

    .news-desc {
      font-size: 10px;
    }
  }
</style>
