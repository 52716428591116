<template>
  <div
    id="carouselExampleIndicators"
    class="carousel"
    data-bs-ride="carousel"
    data-bs-pause="false"
    data-bs-interval="3000"
  >
    <div class="carousel-inner">
      <img
        src="../assets/Dashboard/BannerBackground.png"
        class="banner-background"
      />
      <img
        src="../assets/Dashboard/banner-background-mobile.png"
        class="banner-background-mobile"
      />
      <div
        v-for="content in carouselContents"
        v-bind:class="{ active: content.active }"
        class="carousel-item"
        :key="content.title"
      >
        <div class="row">
          <div
            class="carousel-text fade-in-title align-self-center col-md-5 col-lg-5"
          >
            <h1>
              {{ content.title }}
            </h1>
            <p>
              As your food solution partner, Foodex Inti Ingredients always seek
              innovative ways to provide additional value to our customers and
              to meet their needs, interests, and expectations.
            </p>
          </div>
          <img
            class="carousel-image col-6 col-xs-12 fade-in-image"
            :src="content.image"
            alt="First slide"
          />
        </div>
      </div>
      <ol class="carousel-indicators">
        <span
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="0"
          class="indicator active"
          aria-current="true"
          aria-label="Slide 1"
        ></span>
        <span
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="1"
          aria-label="Slide 2"
          class="indicator"
        ></span>
        <span
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="2"
          aria-label="Slide 3"
          class="indicator"
        ></span>
        <span
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="3"
          aria-label="Slide 4"
          class="indicator"
        ></span>
      </ol>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Carousel',
    data() {
      return {
        carouselContents: [
          {
            title: 'Your Reliable Partner for Excellent Food',
            description:
              'As your food solution partner, Foodex ?Inti Ingredients always seek innovative ways to provide additional value to our customers and to meet their needs, interests, and expectations.',
            image: require('../assets/Dashboard/Home3.png'),
            active: true,
          },
          {
            title: 'Innovation',
            description:
              'Innovation teams will always suggest and create products that are friendly, flavourful and acceptable to market. Our R&D is equipped with resources and experience to create customized innovative products that are sure to tantalize your tastebuds.',
            active: false,
            image: require('../assets/Dashboard/Home4.png'),
          },
          {
            title: 'Quality Control & Assurance',
            description:
              'Quality Control & Assurance teams are committed to provide safe & consistent quality products for your total satisfaction. Under QAQC team PT FOODEX INTI INGREDIENTS is FSSC 22000, GMP & Halal certified manufacturer.',
            active: false,
            image: require('../assets/Dashboard/Home2.png'),
          },
          {
            title: 'Customer Relation',
            description:
              'Customer Relation is consistently supported to meet and exceed customers expectations. Our Assistance is to provide you with solutions that you need and to support you with trendy product market development',
            active: false,
            image: require('../assets/Dashboard/Home1.png'),
          },
        ],
      };
    },
  };
</script>

<style scoped>
  .banner-background {
    background-size: cover;
    width: 100%;
  }

  .banner-background-mobile {
    background-size: cover;
    width: 100%;
  }

  h1 {
    margin-bottom: 25px;
    color: #035795;
    font-family: Museo-bold;
  }

  p {
    font-family: Museo-thin;
    color: #035795;
  }

  .indicator {
    height: 10px;
    width: 10px !important;
    background-color: white;
    border-radius: 100%;
    border: 2px solid #035795;
    display: inline-block;
  }

  .carousel-indicators .active {
    height: 10px;
    width: 10px;
    background-color: #035795;
    border-radius: 50%;
    display: inline-block;
  }

  @media only screen and (min-width: 2000px) {
    .carousel-indicators {
      position: absolute !important;
      justify-content: flex-start !important;
      top: 60% !important;
    }

    .carousel-image {
      left: 15% !important;
    }
  }

  @media only screen and (min-width: 1500px) and (max-width: 2000px) {
    .carousel-image {
      right: -10% !important;
    }

    .carousel-indicators {
      position: absolute !important;
      justify-content: flex-start !important;
      top: 65% !important;
    }
  }
  @media only screen and (min-width: 1300px) {
    .banner-background-mobile {
      display: none;
    }

    .carousel-indicators {
      position: absolute !important;
      justify-content: flex-start !important;
      top: 70%;
      margin-left: 246px;
      padding-left: 30;
    }
    .carousel-image {
      margin-top: 12%;
      border-radius: 50px;
      margin-right: -150px;
      height: 100%;
      object-fit: cover;
      position: relative;
      right: -4%;
    }
    .carousel-text {
      margin: 0 0px 0 250px;
      width: 30%;
    }

    .fade-in-image {
      -webkit-animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
        both;
      animation: fade-in-right 2s cubic-bezier(0.1, 0.575, 0.565, 1) both;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1299px) {
    .fade-in-image {
      -webkit-animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
        both;
      animation: fade-in-right 2s cubic-bezier(0.1, 0.575, 0.565, 1) both;
    }
    .banner-background-mobile {
      display: none;
    }

    .row {
      justify-content: space-evenly;
    }

    .carousel-indicators {
      position: absolute !important;
      justify-content: flex-start !important;
      margin-left: 0% !important;
      top: 70%;
      padding-left: 60px;
    }

    .carousel-image {
      margin-top: 15%;
      border-radius: 50px;
      margin-right: -100px;
    }

    .carousel-text {
      min-width: 40%;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {
    .fade-in-image {
      -webkit-animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
        both;
      animation: fade-in-right 2s cubic-bezier(0.1, 0.575, 0.565, 1) both;
    }
    .banner-background-mobile {
      display: none;
    }

    .row {
      justify-content: space-evenly;
    }

    .carousel-indicators {
      position: absolute !important;
      justify-content: flex-start !important;
      margin-left: 0 !important;
      top: 72%;
      padding-left: 45px;
    }
    .carousel-image {
      margin-top: 15%;
      border-radius: 50px;
      margin-right: -80px;
      position: relative;
      right: 10px;
    }

    .carousel-text {
      min-width: 40%;
    }

    h1 {
      font-size: 30px;
    }

    p {
      font-size: 15px;
    }
  }

  @media only screen and (max-width: 767px) {
    p {
      font-size: 14px;
    }

    .carousel-indicators {
      position: relative !important;
      justify-content: flex-start !important;
      margin-left: 0% !important;
      padding-left: 20px;
      top: -8vh;
    }

    .banner-background {
      display: none;
    }

    .banner-background-mobile {
      margin-top: 200px;
      height: 600px;
      object-fit: cover;
      object-position: bottom;
    }

    .carousel-image {
      height: 450px;
      width: 100%;
      object-fit: cover;
      object-position: top;
      order: 0;
    }

    .carousel-text {
      padding: 35px;
      order: 1;
    }
  }

  /* ----------------------------------------------
  * Generated by Animista on 2021-7-15 23:11:30
  * Licensed under FreeBSD License.
  * See http://animista.net/license for more info.
  * w: http://animista.net, t: @cssanimista
  * ---------------------------------------------- */

  /**
  * ----------------------------------------
  * animation fade-in-top
  * ----------------------------------------
  */
  @-webkit-keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
      overflow: hidden;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-60px);
      transform: translateY(-60px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fade-in-right {
    0% {
      opacity: 0;
      -webkit-transform: translateX(50px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }

  .fade-in-title {
    -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-top 2s cubic-bezier(0.1, 0.575, 0.565, 1) both;
  }
</style>
