<template>
  <div class="manufacturing">
    <Navbar />
    <Jumbotron
      :backgroundImg="background"
      :mobileImage="mobileBackground"
      :title="title"
      :description="description"
      :position="'left'"
    />
    <div class="manufacturing-content">
      <div class="first row align-items-center">
        <img
          src="../assets/Manufacturing/manufacturing-1.png"
          class="manu-image col-md-7 order-1 order-md-0"
        />
        <div
          class="col-md-5 align-self-center order-0 manufacturing-text order-xl-1"
        >
          <h1>Manufacturing</h1>
          <p class="first-ph">
            Our manufacturing and warehouse teamwork simultaneously to improve
            the production capacity also creating a more efficient work ethic in
            the factory.
          </p>
          <span>
            Our dedicated teams offer integrated service from manufacturing
            testing to distribution. By controlling all supply chains of
            manufacturing we guarantee ecellent products with
          </span>
          <b>
            high level of quality, prompt order response and on-time deliver.
          </b>
        </div>
      </div>
      <div class="second row align-items-center">
        <div
          class="manufacturing-text col-md-5 align-self-center order-0 order-sm-1 order-md-0 order-xl-1"
        >
          <h1>Warehouse & Distribution</h1>
          <p class="second-ph">
            In PT Foodex Inti Ingredients our warehouse & distribution
            department is equipped with integrated WMS (Warehouse Management
            System) and TMS (Transportation Management system) which ensure
            accuracy and stable temperature control until products reach our
            partners place.
          </p>
        </div>
        <img
          class="manu-image col-md-7 order-sm-0 order-1 order-md-1"
          src="../assets/Manufacturing/manufacturing-2.png"
        />
      </div>
    </div>
    <img
      class="factory-image"
      src="../assets/Manufacturing/manufacturing-3.png"
    />
    <div class="manufacturing-content third-ph text-center">
      <p>
        In PT Foodex Inti Ingredients our warehouse & distribution department is
        equipped with integrated WMS (Warehouse Management System) and TMS
        (Transportation Management system) which ensure accuracy and stable
        temperature control until products reach our partners place.
      </p>
      <p>
        Our warehouse is also equipped with an Accurate Tracing System so that
        product's safety is well maintained from the processing of raw materials
        to production then going to storage and to distribution.
      </p>
      <p>
        Moreover, our warehouse is equipped with a Selective Racking System with
        a capacity of more than 2500 pallet positions. We also managed multiple
        climate zones which are ambient, chilled/ AC and frezer temperature. We
        have a strict temperature monitoring system in our warehouse so that we
        can guarantee the quality and freshness of our products
      </p>
    </div>
    <ColorfulOptions />
    <Footer />
  </div>
</template>

<script>
  import Navbar from '../components/Navbar.vue';
  import Jumbotron from '../components/Jumbotron.vue';
  import ColorfulOptions from '../components/ColorfulOptions.vue';
  import Footer from '../components/Footer.vue';

  export default {
    name: 'Manufacturing',
    components: { Navbar, Jumbotron, ColorfulOptions, Footer },
    data() {
      return {
        background: {
          backgroundImage: `url(${require('../assets/Manufacturing/manufacturing-banner.png')})`,
        },
        mobileBackground: {
          backgroundImage: `url(${require('../assets/Manufacturing/manufacture-banner-mobile.png')})`,
        },
        title: 'Manufacturing & Distribution',
        description:
          'In Foodex we believe that making product right the first time is a mandatory. With that in mind our manufacturing team takes process from raw materials to finished products very seriously',
      };
    },
    mounted() {
      document.body.scrollTop += 10;
    },
  };
</script>

<style scoped>
  .manufacturing {
    font-family: Museo-thin;
    color: #035795;
    overflow-x: hidden;
  }

  .first-ph {
    color: #c78947;
  }

  h1 {
    font-family: Museo-bold;
  }

  .third-ph {
    margin: auto;
    padding-bottom: 50px;
    width: 100%;
  }

  .factory-image {
    background-size: cover;
    object-fit: cover;
    width: 100%;
    height: 35vw;
  }

  @media only screen and (min-width: 768px) {
    .manufacturing-content {
      text-align: start;
      padding: 5vw;
      font-size: 15px;
      max-width: 1100px;
      margin-right: auto;
      margin-left: auto;
    }

    .manu-image {
      height: 100%;
    }
  }

  @media only screen and (max-width: 767px) {
    .manufacturing-content {
      margin-top: 30px;
      margin-bottom: 100px;
      padding: 15px;
      text-align: center;
    }

    .manufacturing-text {
      margin: 25px 0 30px 0;
    }
  }
</style>
