<template>
  <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-dark">
    <router-link to="/">
      <img
        class="foodex-logo foodex-logo-mobile"
        src="../assets/Dashboard/FoodexLogo.png"
      />
    </router-link>
    <button
      class="navbar-toggler ms-auto collapsed"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
      <div class="close-icon py-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          fill="currentColor"
          class="bi bi-x"
          viewBox="0 0 16 16"
        >
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </div>
    </button>
    <div
      class="collapse justify-content-between navbar-collapse"
      id="navbarNav"
    >
      <div>
        <router-link to="/">
          <img
            class="foodex-logo foodex-logo-desktop"
            src="../assets/Dashboard/FoodexLogo.png"
          />
        </router-link>
      </div>
      <ul class="navbar-nav align-items-center">
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle desktop-dropdown"
            id="navbarDropdown"
            role="button"
            aria-expanded="false"
          >
            Our Story
          </a>
          <a
            class="nav-link dropdown-toggle mobile-dropdown"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Our Story
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <router-link to="/about">
              <li>
                <a class="dropdown-item">About Us</a>
              </li>
            </router-link>
            <hr class="dropdown-divider-desktop" />
            <router-link to="/manufacturing">
              <li>
                <a class="dropdown-item">Manufacturing</a>
              </li>
            </router-link>
            <hr class="dropdown-divider-desktop" />
            <router-link to="/quality">
              <li><a class="dropdown-item">Quality</a></li>
            </router-link>
            <hr class="dropdown-divider-desktop" />
            <router-link to="/rnd">
              <li><a class="dropdown-item">R&D</a></li>
            </router-link>
          </ul>
          <hr class="dropdown-divider" />
        </li>

        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle desktop-dropdown"
            id="navbarDropdown"
            role="button"
            aria-expanded="false"
          >
            Our Products
          </a>
          <a
            class="nav-link dropdown-toggle mobile-dropdown"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Our Products
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <router-link to="/products/naturalsolutions">
              <li>
                <a class="dropdown-item">Natural Solution</a>
              </li>
            </router-link>
            <hr class="dropdown-divider-desktop" />
            <router-link to="/products/menusolutions">
              <li>
                <a class="dropdown-item">Menu Solution</a>
              </li>
            </router-link>
            <hr class="dropdown-divider-desktop" />
            <router-link to="/products/tastesolutions">
              <li><a class="dropdown-item">Taste Solution</a></li>
            </router-link>
            <hr class="dropdown-divider-desktop" />
            <router-link to="/products/tradingpartners">
              <li><a class="dropdown-item">Trading Partners</a></li>
            </router-link>
          </ul>
          <hr class="dropdown-divider" />
        </li>

        <li class="nav-item">
          <router-link class="nav-link" to="/news">News & Updates</router-link>
          <hr class="dropdown-divider" />
        </li>

        <li class="nav-item">
          <router-link class="nav-link" to="/contact">Contact Us</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
  export default {
    name: 'Navbar',
  };
</script>

<style scoped>
  nav {
    background-color: #035795 !important;
    font-family: Museo-thin;
  }

  .navbar-nav > li {
    margin-left: 50px;
    margin-right: 50px;
  }

  .navbar-toggler.collapsed .close-icon {
    display: none;
  }

  .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
    display: inline;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  a {
    text-decoration: none !important;
  }

  hr {
    margin: 5px 0 5px 0;
  }

  .dropdown-item {
    color: #035795;
  }

  .dropdown-item:hover {
    font-family: Museo-bold;
  }

  .dropdown-menu {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    top: 80px;
    left: -30px;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #fff;
    background-color: transparent;
  }

  .dropdown-item:hover {
    background-color: transparent;
  }

  .dropdown-divider-desktop {
    height: 1px;
    color: #035795;
    opacity: 1;
  }

  .nav-item {
    margin-right: 20px;
    margin-left: 20px;
    font-size: 13px;
  }

  .nav-link {
    color: white !important;
  }

  .navbar-toggler {
    border: none !important;
  }

  .foodex-logo {
    height: 80px;
    width: 100%;
    margin-left: 30%;
    z-index: 2;
  }

  @media only screen and (max-width: 991px) {
    .dropdown-item {
      padding: 5px 30px 5px 30px;
      color: white;
    }

    .foodex-logo {
      margin-left: 0.5rem;
      height: 50px;
      width: auto;
    }

    .desktop-dropdown {
      display: none;
    }

    .foodex-logo-desktop {
      display: none;
    }

    .navbar-nav {
      margin-top: 40px;
      text-align: center;
      z-index: 100;
      height: 100vh;
    }

    .dropdown-menu {
      background-color: transparent !important;
      text-align: center;
      border: none;
      color: white;
    }

    .dropdown-item:hover {
      background-color: transparent;
    }

    .dropdown-divider {
      color: white;
      width: 120px;
      margin: auto;
    }

    .dropdown-divider-desktop {
      display: none;
    }

    .nav-item {
      text-align: center;
      font-size: 20px;
    }
    .show {
      overflow-y: hidden !important;
    }
  }

  @media only screen and (min-width: 991px) {
    .navbar {
      padding-top: 0;
      padding-bottom: 0;
    }

    .dropdown {
      height: 80px;
      padding-top: 22px;
    }

    .dropdown:hover > .dropdown-menu {
      display: block;
    }

    .foodex-logo-mobile {
      display: none;
    }

    .foodex-logo {
      position: relative;
      top: 30px;
    }

    .mobile-dropdown {
      display: none;
    }

    .dropdown-divider {
      display: none;
    }

    .dropdown-menu {
      background-color: rgb(221, 221, 221);
      border: none;
      color: white;
      width: 170px;
    }
  }
</style>
