<template>
  <div class="taste-solutions">
    <div class="description text-center taste-content">
      <div id="savoury-seasonings" class="main-title text-center">
        <div
          class="row justify-content-center align-items-center taste-title-mobile"
        >
          <img class="flower col-4" src="../../assets/Dashboard/flower.png" />
          <div class="ready-title col-7 text-center">
            Savoury Seasonings
          </div>
          <img class="flower col-4" src="../../assets/Dashboard/flower.png" />
        </div>
        <div class="col taste-title-desktop">
          <img class="flower" src="../../assets/Dashboard/flower.png" />
          <span class="ready-title">Savoury Seasonings</span>
          <img class="flower" src="../../assets/Dashboard/flower.png" />
        </div>
      </div>
      <p class="taste-description">
        Our seasoning blends have been widely used in snack industry, meat
        processing industries, noodle industries and food service market
        (HORECA; Hotel, Reastaurant, Cafe and catering). In Foodex, we have a
        dedicated R&D team focusing on searching for latest flavor trend
        suitable for local and international taste. Our highly skilled
        application team has in-depth knowledge of ingredients, their functional
        properties and their aplpication. We can help you design and innovate
        concepts as well as choose suitable ingredients for specific formulation
        and process including vegetarian, low-salt, MSG-free or other customized
        requirements.
      </p>
      <div class="row seasonings">
        <div class="seasoning col-md-6 col-12">
          <h2>Complete Seasonings</h2>
          <p>
            Is conveniently designed for agile manufacturers with continuous
            need for innovative, ready to use flavor solution.
          </p>
        </div>
        <div class="seasoning col-md-6 col-12">
          <h2>Premix Seasonings</h2>
          <p>
            The semi blend offers a way to combine our flavor creation with your
            own formulation to create unique flavor for your products.
          </p>
        </div>
      </div>
      <h3>Available in - powder or paste form</h3>
      <img class="table" src="../../assets/Products/taste-table1.png" />
    </div>
    <div class="parallax"></div>
    <img class="mid-image" src="../../assets/Products/taste-parallax.jpg" />
    <div
      v-for="(taste, i) in tastes"
      :key="i"
      v-bind:id="taste.id"
      class="extracts"
    >
      <div class="description text-center taste-content">
        <div
          class="row justify-content-center align-items-center taste-title-mobile "
        >
          <img class="flower col-4" src="../../assets/Dashboard/flower.png" />
          <div class="ready-title col-7 text-center">
            {{ taste.title }}
          </div>
          <img class="flower col-4" src="../../assets/Dashboard/flower.png" />
        </div>
        <div class="col taste-title-desktop">
          <img class="flower" src="../../assets/Dashboard/flower.png" />
          <span class="ready-title">{{ taste.title }}</span>
          <img class="flower" src="../../assets/Dashboard/flower.png" />
        </div>

        <div class="taste-description sweets" v-html="taste.description"></div>
        <img class="table" :src="taste.table" />
      </div>
      <div class="parallax2" v-bind:style="taste.parallax"></div>
      <img class="mid-image" :src="taste.image" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NaturalSolutions',
    props: ['subsection'],
    // created() {
    //   if (this.subsection) {
    //     var elem = document.getElementById(this.section);
    //     elem.scrollIntoView();
    //   }
    //   this.$emit('clearSection');
    // },
    watch: {
      subsection(section) {
        if (section) {
          var elem = document.getElementById(section);
          elem.scrollIntoView();
        }
        this.$emit('clearSection');
      },
    },
    mounted() {
      if (this.$route.params.section) {
        var elem = document.getElementById(this.$route.params.section);
        elem.scrollIntoView();
        elem.scrollTop -= 20;
      } else {
        document.body.scrollTop += 10;
      }
      this.$emit('clearSection');
    },
    data() {
      return {
        tastes: [
          {
            id: 'sweet-seasonings',
            title: 'Sweet Seasonings & Drinks',
            description: `<p>
              In recent years, thee has been fast increase in demand of sweet foods
              and drinks in Indonesia. Foodex Inti Ingredients is pleased to offer
              sweet seasonings suitable for application in extruded, fried and
              backed snacks, cookies, biscuits and cream fillings.
            </p>
            <p>
              In addition, foodex offers extensive list of powdered beverage mix
              including flavored tea, coffee and chocolate drinks, fruit favored
              drink and other seasonal in-trend drink. Most of our products are
              ready to serve with simple addition of waste, and can be served cold,
              hot or blended.
            </p>
            <p>
              In foodex, we have a dedicated R&D team focusing on searching for
              latest sweet flavor trend suitable for local and international taste.
              We alays take a tip to survey flavor preferences of our end consumers,
              rangin from children to adults. Based on the survey, we work together
              with our customers to creat excellent flavor.
            </p>`,
            image: require('../../assets/Products/taste-parallax2.jpg'),
            table: require('../../assets/Products/taste-table2.png'),
            parallax: {
              backgroundImage: `url(${require('../../assets/Products/taste-parallax2.jpg')})`,
            },
          },
          {
            id: 'sambals',
            title: 'Sambals',
            description: `<p>
              To enhance eating experience most of the consumers like to have "the kick experience" during their dining or snacking time.
              One of this experience can be found by adding spicy taste to the dish or snacks which usually known by adding sambals. Sambals is a very traditional name and
              process, where usually the cook will manually grind the chillies together with other herbs & spices
            </p>
            <p>
              Specially to Indonesians, having spicy profile has always been the first choice when it comes to 'kick experience'. Here in Foodex,
              we handpicked all fresh chills & ingredients then we adjust them during manufacturing process. The output will resemple home cooking but been
              upgraded to a delicious, standard and hygienic sambals.
            </p>`,
            image: require('../../assets/Products/taste-parallax3.jpg'),
            table: require('../../assets/Products/taste-table3.png'),
            parallax: {
              backgroundImage: `url(${require('../../assets/Products/taste-parallax3.jpg')})`,
            },
          },
          {
            id: 'condiments',
            title: 'Sauce, Paste & Condiments ',
            description: `<p>
              Foodex offers high quality sauces and condiment products that can be mixed with our clients culinary creation.
              We believe that putting some sauces as topping or simply have them ready for dipping will enhance the eating experience. Together we can create magnificent foods so consumers
              will have a wonderful dining experience.
            </p>
            <p>
            We have some created some sauces for your selections;
            </p>`,
            image: require('../../assets/Products/taste-parallax4.jpg'),
            table: require('../../assets/Products/taste-table4.png'),
            parallax: {
              backgroundImage: `url(${require('../../assets/Products/taste-parallax4.jpg')})`,
            },
          },
          {
            id: 'beverage-solutions',
            title: 'Beverage Solutions',
            description: `<p>
              We carry a complete solution for your beverage needs. Our solutions can come in pwder seasonings, liquid sauce/paste or dronk toppings. Whether you look for convenience, new trend or new flavor, 
              we always custom made our products just for you
            </p>`,
            image: require('../../assets/Products/taste-parallax5.jpg'),
            table: require('../../assets/Products/taste-table6.png'),
            parallax: {
              backgroundImage: `url(${require('../../assets/Products/taste-parallax5.jpg')})`,
            },
          },
        ],
      };
    },
  };
</script>

<style scoped>
  #savoury-seasonings {
    scroll-margin-top: 7em;
  }

  #condiments {
    scroll-margin-top: 4em;
  }

  #sweet-seasonings {
    scroll-margin-top: 5em;
  }

  #beverage-solutions {
    scroll-margin-top: 5em;
  }

  #sambals {
    scroll-margin-top: 4em;
  }

  .taste-solutions {
    font-family: Museo-thin;
  }

  .taste-content {
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 1100px;
  }

  .ready-title {
    color: #035795;
    font-family: Museo-bold;
  }

  h2 {
    font-family: Museo-bold;
  }

  h3 {
    font-family: Museo-bold;
  }

  .mid-image {
    width: 100%;
  }

  .table {
    width: 70%;
  }

  .description {
    margin: auto;
    width: 100%;
    color: #035795;
  }

  .extract {
    margin-bottom: 30px;
    color: #035795;
  }

  .extract-image {
    width: auto;
    height: 200px;
  }

  @media only screen and (min-width: 768px) {
    .flower {
      position: relative;
      top: -10px;
      margin: 0 30px 0 30px;
      width: 40px;
      height: 100%;
    }

    .ready-title {
      font-size: 35px;
    }

    .description {
      padding: 50px;
    }
    .taste-title-mobile {
      display: none;
    }

    .taste-title-desktop {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .table {
      margin-top: 50px;
    }

    .parallax {
      background-image: url('../../assets/Products/taste-parallax.jpg');
      min-height: 400px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      background-attachment: fixed;
      display: block;
    }

    .parallax2 {
      min-height: 400px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-attachment: fixed;
      display: block;
    }

    .mid-image {
      display: none;
    }
    .seasonings {
      margin-top: 40px;
      margin-bottom: 30px;
    }
  }

  @media only screen and (max-width: 767px) {
    .taste-content {
      max-width: 1100px;
      padding: 0;
    }

    .flower {
      top: -10px;
      width: 60px;
      height: 100%;
    }
    .mid-image {
      height: 350px;
      object-fit: cover;
    }
    .parallax {
      display: none;
    }
    .parallax2 {
      display: none;
    }

    .table {
      margin-top: 20px;
      padding: 0 12px 0 12px;
    }

    .description {
      margin-top: 30px;
    }

    .taste-title-mobile {
      margin-top: 10px;
      margin-bottom: 20px;
    }

    .taste-title-desktop {
      display: none;
    }

    .row {
      padding: 0;
    }

    .ready-title {
      font-size: 3.5vh;
    }

    .taste-description {
      margin: 0 30px 0 30px;
      font-size: Museo-thin;
    }

    .table {
      width: 100%;
    }

    .seasoning {
      margin: 20px 0 20px 0;
    }

    .seasonings {
      margin-top: 40px;
    }
  }
</style>
