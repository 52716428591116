<template>
  <div class="about row align-items-center mx-auto">
    <img
      class="col-md-6 col-sm-12 col-12 about-img"
      src="../assets/Dashboard/About-food-image.png"
    />
    <div class="about-text col-12 col-md-6 col-sm-12">
      <p class="about-us-title">About us</p>
      <h1>From Local to Global</h1>
      <p class="about-us-desc">
        Established in 1995, PT Foodex Inti Ingredients is one of the leading
        manufacturers of food seasoning and food ingredients in Indonesia. We
        provide solutions for our B2B partners in: Noodle, snack, Processed
        Meat, and Food Service Industry
      </p>
      <!-- <button type="button" class="learn-more-button">Learn More</button> -->
      <router-link to=/about>
        <Button :buttonText="'Learn More'" />
      </router-link>
    </div>
  </div>
</template>

<script>
  import Button from '../components/Button.vue';
  export default {
    name: 'About',
    components: { Button },
  };
</script>

<style scoped>
  .about {
    display: flex;
    padding: 20px;
    margin-bottom: 80px;
    margin-top: 50px;
    max-width: 1100px;
  }

  h1 {
    font-size: 40px;
    color: #035795;
  }

  .about-us-desc {
    color: #035795;
  }

  .about-us-title {
    color: #d40833;
    font-size: 30px;
  }

  .about-image {
    height: 100%;
  }

  
  /* 
  .learn-more-button {
    border: 2px solid #035795;
    border-radius: 30px;
    width: 200px;
    height: 50px;
    background: transparent;
    color: #035795;
  } */

  @media only screen and (min-width: 1200px) {
    .about-text {
      position: relative;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    .about-text {
      position: relative;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {
    .about-text {
      position: relative;
      font-size: 1.5vw;
    }
  }

  @media only screen and (max-width: 767px) {
    h1 {
      font-size: 30px;
      color: #035795;
    }

    .about-us-title {
      font-size: 20px;
    }

    .about-text {
      position: relative;
      text-align: center;
    }
  }
</style>
