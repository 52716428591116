<template>
  <div class="quality">
    <Navbar />
    <Jumbotron
      :backgroundImg="background"
      :mobileImage="mobileBackground"
      :title="title"
      :position="'left'"
      :description="description"
    />
    <div class="quality-content">
      <div class="row">
        <p class="text-center quality-text">
          We only source our raw materials from high quality suppliers to assure
          product safety, reliability, and consistency. In addition, we have
          developed a supply chain control system that quarantees tracability of
          our products, from raw materials, production process to finished
          goods.
        </p>
        <img
          src="../assets/Quality/quality-1.png"
          class="quality-image col-md-7"
        />
        <div class="col-md-5 quality-text align-self-center">
          <p class="first-ph">
            Our dedicated team manages the daily quality control activities,
            working in our in-house laboratory and in collaboration with
            qualified external laboratories.
          </p>
          <div>
            Foodex Inti ingredients is an FSSC 22000, GMP and Halal-Certified
            manufacturer and supplier.
          </div>
          <img class="license" src="../assets/Quality/quality-gmp.png" />
          <img class="license" src="../assets/Quality/quality-halal.png" />
          <img class="license" src="../assets/Quality/quality-fssc.png" />
        </div>
      </div>
    </div>

    <ColorfulOptions />
    <Footer />
  </div>
</template>

<script>
  import Navbar from '../components/Navbar.vue';
  import Jumbotron from '../components/Jumbotron.vue';
  import ColorfulOptions from '../components/ColorfulOptions.vue';
  import Footer from '../components/Footer.vue';

  export default {
    name: 'Manufacturing',
    components: { Navbar, Jumbotron, ColorfulOptions, Footer },
    data() {
      return {
        background: {
          backgroundImage: `url(${require('../assets/Quality/quality-banner.png')})`,
        },
        mobileBackground: {
          backgroundImage: `url(${require('../assets/Quality/quality-banner-mobile.png')})`,
        },
        title: 'Quality',
        description:
          'Foodex Inti Ingredients is an approved ingredient supplier to many multinational leading food and beverage manufacturers. As such, we seek to achieve and maintain strict quality standards.',
      };
    },
    mounted() {
      document.body.scrollTop += 10;
    },
  };
</script>

<style scoped>
  .quality {
    font-family: Museo;
    color: #035795;
    overflow-x: hidden;
  }

  .quality-image {
    height: 100%;
  }

  .first-ph {
    color: #c78947;
  }

  .third-ph {
    margin: auto;
    padding: 50px 0 50px 0;
    width: 60%;
  }

  .license {
    margin-right: 20px;
    margin-top: 20px;
    width: 7vw;
    height: 100%;
  }

  @media only screen and (max-width: 767px) {
    .quality-content {
      text-align: center;
      margin-bottom: 70px;
      padding: 15px;
    }

    .quality-text {
      margin-top: 50px;
      margin-bottom: 50px;
    }

    .license {
      width: 14vw;
      height: 100%;
    }
  }

  @media only screen and (min-width: 768px) {
    .quality-content {
      padding: 5vw;
      font-size: 15px;
      max-width: 1100px;
      margin-right: auto;
      margin-left: auto;
    }

    .quality-text {
      margin-bottom: 70px;
    }
  }
</style>
