<template>
  <div>
    <div class="top-options row align-items-center">
      <div class="col-md-4 col-sm-6 col-6 explore align-items-center">
        <h1>Explore More <br />about Foodex</h1>
        <hr />
      </div>
      <div class="col-md-8 col-sm-6 col-6 red-container color-container">
        <router-link
          v-if="currentRoute === '/about' || currentRoute === '/quality'"
          to="/manufacturing"
        >
          <img
            class="section img-fluid manufacturing-image"
            src="../assets/Options/manufacturing-red.png"
          />
          <h1 class="colorful-text">Manufacturing</h1>
        </router-link>
        <router-link
          v-if="currentRoute === '/rnd' || currentRoute === '/manufacturing'"
          to="/about"
        >
          <img
            class="section img-fluid"
            src="../assets/Options/about-red.png"
          />
          <h1 class="colorful-text">About</h1>
        </router-link>
      </div>
    </div>
    <div class="bottom-options row">
      <div class="col-md-8 col-sm-6 col-6 blue-container color-container">
        <router-link to="/manufacturing" v-if="currentRoute === '/rnd'">
          <img
            class="section img-fluid manufacturing-image"
            src="../assets/Options/manufacturing-blue.png"
          />
          <h1 class="colorful-text">Manufacturing</h1>
        </router-link>
        <router-link to="/rnd" v-else>
          <img class="section img-fluid" src="../assets/Options/rnd-blue.png" />
          <h1 class="colorful-text">Research & Development</h1>
        </router-link>
      </div>
      <div class="col-md-4 col-sm-6 col-6 yellow-container color-container">
        <router-link v-if="currentRoute !== '/quality'" to="/quality">
          <img
            class="section img-fluid quality-image"
            src="../assets/Options/quality-yellow.png"
          />
          <h1 class="colorful-text">Quality</h1>
        </router-link>
        <router-link to="/about" v-if="currentRoute == '/quality'">
          <img
            class="section img-fluid"
            src="../assets/Options/about-yellow.png"
          />
          <h1 class="colorful-text">About</h1>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ColorfulOptions',
    data() {
      return {
        currentRoute: this.$router.currentRoute.path,
      };
    },
    created() {},
  };
</script>

<style scoped>
  .row > [class*='col-'] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .top-options {
    background-color: rgb(209, 209, 209);
  }

  h1 {
    font-size: 35px;
    font-family: Museo;
  }

  hr {
    height: 3px;
    color: #035795;
    opacity: 100%;
    width: 40%;
  }

  img {
    object-fit: cover;
  }

  .red-container {
    position: relative;
  }

  .color-container:hover > a > .colorful-text {
    font-size: 45px;
  }

  .blue-container {
    position: relative;
  }

  .rnd {
    width: 100%;
  }

  .yellow-container {
    position: relative;
  }

  .explore {
    position: relative;
    left: 8.5%;
  }

  .colorful-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    transition: 0.3s;
  }

  @media only screen and (max-width: 768px) {
    .section {
      height: 50vw;
      width: 60vw;
    }

    h1 {
      font-size: 20px;
    }

    .quality-image {
      object-position: left;
    }

    .manufacturing-image {
      object-position: right;
    }

    .color-container:hover > a > .colorful-text {
      font-size: 30px;
    }
  }
  @media only screen and (min-width: 768px) {
    .section {
      width: 100%;
      height: 100%;
    }

    .color-container {
      white-space: nowrap;
    }
  }
</style>
