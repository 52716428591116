<template>
  <div class="products">
    <Navbar />
    <Jumbotron
      :backgroundImg="products[params].background"
      :mobileImage="products[params].mobileBackground"
      :title="products[params].title"
      :position="'right'"
      :fontColor="'#035795'"
      :description="products[params].description"
    />
    <mid-navbar @changeSection="changeSection($event)" />
    <natural-solution
      @clearSection="clearSection($event)"
      v-if="params === 'naturalsolutions'"
      ref="naturalSolution"
      :subsection="subsection"
    />
    <ready-to-meal
      @clearSection="clearSection($event)"
      :subsection="subsection"
      v-else-if="params === 'menusolutions'"
    />
    <taste-solutions
      @clearSection="clearSection($event)"
      :subsection="subsection"
      v-else-if="params === 'tastesolutions'"
    />
    <trading-partners
      @clearSection="clearSection($event)"
      :subsection="subsection"
      v-else-if="params === 'tradingpartners'"
    />
    <Footer />
  </div>
</template>

<script>
  import Navbar from '../components/Navbar.vue';
  import Jumbotron from '../components/Jumbotron.vue';
  import MidNavbar from '../components/MidNavbar.vue';
  import ReadyToMeal from '../components/Products/ReadyToMeal.vue';
  import NaturalSolution from '../components/Products/NaturalSolutions.vue';
  import TasteSolutions from '../components/Products/TasteSolutions.vue';
  import TradingPartners from '../components/Products/TradingPartners.vue';
  import Footer from '../components/Footer.vue';

  export default {
    name: 'Products',
    components: {
      Navbar,
      Jumbotron,
      MidNavbar,
      ReadyToMeal,
      TradingPartners,
      NaturalSolution,
      TasteSolutions,
      Footer,
    },

    data() {
      return {
        // naturalSolutionKey: 1,
        params: this.$route.params.page,
        subsection: '',
        products: {
          menusolutions: {
            title: 'Menu Solutions',
            background: {
              backgroundImage: `url(${require('../assets/Products/ready-to-meal-banner.png')})`,
            },
            mobileBackground: {
              backgroundImage: `url(${require('../assets/Products/ready-to-meal-banner-mobile.png')})`,
            },
            description:
              'With a strong focus on R&D, Foodex Inti Ingredients is on the forefront to provide innovative food ingredient solutions.',
          },
          naturalsolutions: {
            title: 'Natural Solutions',
            background: {
              backgroundImage: `url(${require('../assets/Products/natural-solution-banner.png')})`,
            },
            mobileBackground: {
              backgroundImage: `url(${require('../assets/Products/ready-to-meal-banner-mobile.png')})`,
            },
            description:
              'Developed through modern and advanced process, also combined with our attention to quality and cost-effective Raw Materials, our Natural Products offer high performance to your application.',
          },
          tastesolutions: {
            title: 'Taste Solutions',
            background: {
              backgroundImage: `url(${require('../assets/Products/taste-solution-banner.png')})`,
            },
            mobileBackground: {
              backgroundImage: `url(${require('../assets/Products/ready-to-meal-banner-mobile.png')})`,
            },
            description:
              'Foodex Inti Ingredients is highly specializd in formulating seasoning blends containing spices, herbs and other flavor/functional ingredients to create unique and flavorful recipes.',
          },
          tradingpartners: {
            title: 'Trading Partners',
            background: {
              backgroundImage: `url(${require('../assets/Products/trading-partners-banner.png')})`,
            },
            mobileBackground: {
              backgroundImage: `url(${require('../assets/Products/ready-to-meal-banner-mobile.png')})`,
            },
            description:
              'In addition to manufacturing good quality products, PT Foodex Inti Ingredients also collaborates with several selected global partners. The sole distributorship in Indonesia is to give a comprehensive solution to its customers.',
          },
        },
      };
    },
    methods: {
      changeSection(section) {
        this.subsection = section;
      },
      clearSection() {
        this.subsection = '';
      },
      changePage(params, section) {
        this.subsection = section;
        this.$router
          .push({
            name: 'product',
            params: { page: `${params}`, section: `${section}` },
          })
          .catch(() => {});
        // this.$emit('changeSection', section);
      },
    },
  };
</script>
<style scoped>
  .products {
    overflow-x: hidden;
  }
</style>
