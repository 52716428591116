<template>
  <div class="contact">
    <navbar />
    <Jumbotron
      :backgroundImg="background"
      :mobileImage="mobileBackground"
      :mobileMiddle="true"
      :title="title"
      :position="'middle'"
      :description="description"
    />
    <div class="row align-items-center contact-description contact-section">
      <img
        class="career-image col-md-6 col-sm-6 col-lg-6 col-xl-6 col-12"
        src="../assets/Contact/contact1.png"
      />
      <p class="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-12 description">
        At the present moment we have an excellent relationship with multiple
        layers of food industries, also we support food services(hotel,
        restaurant, cafe and catering) anywhere in the world. When it comes to
        providing you with excellent products and great customer service, you
        can count on Foodex. Our team members are eager to give you innovative
        and creative products.
      </p>
    </div>
    <div class="row contact-section">
      <div class="col-md-3 col-sm-12 career-intro">
        <p class="contact-inquiries">
          For further information or inquiries, please do no hesitate to contact
          us.
        </p>
        <p class="contact-desc">
          We are looking forward to be your partner, so together we can create
          excellent products for our consumer.
        </p>
      </div>
      <div class="vl col-md-8 col-sm-12 col-xl-8 col-lg-8 career-form col-12">
        <form>
          <div class="form-group">
            <input
              type="text"
              placeholder="Name"
              class="form-control long-input"
              v-model="name"
              autocomplete="nope"
              :disabled="disabled"
            />
            <input
              type="text"
              placeholder="E-mail"
              class="form-control long-input"
              aria-describedby="emailHelp"
              v-model="email"
              autocomplete="nope"
              :disabled="disabled"
            />
            <input
              type="number"
              placeholder="Phone"
              class="form-control long-input"
              v-model="phone"
              :disabled="disabled"
              autocomplete="nope"
            />
            <textarea
              class="form-control"
              rows="3"
              placeholder="Message"
              v-model="message"
              :disabled="disabled"
              autocomplete="nope"
            ></textarea>
            <p class="warning" v-if="this.error">{{ this.error }}</p>
          </div>

          <button
            v-if="!loading"
            type="button"
            @click="sendEmail()"
            class="btn btn-primary"
          >
            SEND MESSAGE
          </button>
          <div v-else class="spinner-border spin" role="status"></div>
        </form>
      </div>
    </div>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1863.2346508781625!2d107.15157898370829!3d-6.356449751486528!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e698ff2b7312973%3A0x3b0f3d7f0cb5029d!2sPT.%20Foodex%20Inti%20Ingredients!5e0!3m2!1sid!2sid!4v1629130394747!5m2!1sid!2sid"
      width="100%"
      height="500"
      style="border:0;"
      allowfullscreen=""
      loading="lazy"
    ></iframe>
    <div class="row description contact-section address">
      <p class="col-md-6 col-12">
        PT FOODEX INTI INGREDIENTS <br />
        Factory: <br />
        Jl. Soka Blok F18 No. 11-12 <br />
        Delta Silicon 3, Lippo Cikarang<br />
        Bekasi 17550 - West Java<br /><br />
        Sales & Marketing: <br />
        Menara Standard Chartered<br />
        32<sup>nd</sup> FLOOR ZONE B<br />
        Jl. Prof.Dr.Satrio No.165 <br />Central Jakarta 12930, Indonesia
      </p>
      <p class="col-md-6 col-12">
        sales@foodexingredients.com<br />

        Tel: +62 21 2928 8650<br />
        Fax: +62 21 2928 8649
      </p>
    </div>
    <Footer />
  </div>
</template>

<script>
  import Navbar from '../components/Navbar.vue';
  import Jumbotron from '../components/Jumbotron.vue';
  import Footer from '../components/Footer.vue';
  import Swal from 'sweetalert2';

  export default {
    name: 'Careers',
    components: { Navbar, Jumbotron, Footer },
    data() {
      return {
        name: '',
        phone: '',
        email: '',
        message: '',
        error: '',
        loading: false,
        disabled: false,
        title: 'Visitor Message',
        background: {
          backgroundImage: `url(${require('../assets/Contact/contact-background-banner.png')})`,
        },
        mobileBackground: {
          backgroundImage: `url(${require('../assets/Contact/contact-mobile-banner.png')})`,
        },
        description:
          'Dear our valuable visitors, PT Foodex Inti Ingredients as your most valuable and trusted partner are commited to provide you with excellent products that meet with International Standards also met with Religious requirement',
      };
    },
    methods: {
      sendEmail() {
        if (this.name.length < 3) {
          this.error = 'Name must be more than 2 letters';
        } else if (this.email.length < 6) {
          this.error = 'Please fill out correct email format';
        } else if (this.phone.length < 3) {
          this.error = 'Please fill out correct phone';
        } else if (this.message.length < 10) {
          this.error = 'Please fill out longer message';
        } else {
          this.error = '';
          this.disabled = true;
          this.loading = true;
          window.Email.send({
            SecureToken: '8299240c-e3fc-4119-9a6f-b3039c7dfce6',
            To: 'sales@foodexingredients.com',
            From: 'smtp.foodexin@gmail.com',
            Subject: `Message from ${this.name}`,
            Body: `
              <b>Name</b>: ${this.name} <br/>
              <b>Phone</b>: ${this.phone},<br/>
              <b>Email</b>: ${this.email}<br/>
              <b>Messages</b>: ${this.message}`,
          }).then((val) => {
            this.error = '';
            if (val !== 'OK') {
              this.disabled = false;
              this.loading = false;
              Swal.fire({
                confirmButtonColor: '#035795',
                text: 'Error occurred.',
              });
            } else {
              this.loading = false;
              this.disabled = false;
              Swal.fire({
                confirmButtonColor: '#035795',
                text: 'Your message has been sent',
              });
              this.name = '';
              this.phone = '';
              this.email = '';
              this.message = '';
            }
          });
        }
      },
    },
  };
</script>

<style scoped>
  .careers {
    overflow-x: hidden;
    font-family: Museo;
  }

  .contact-section {
    margin-top: 30px;
    /* max-width: 1000px; */
    margin-left: auto;
    margin-right: auto;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .warning {
    color: red;
  }

  .btn-primary:hover,
  .btn-primary:focus,
  .btn-primary:active,
  .btn-primary.active {
    color: #285e8e;
    background-color: #fff;
    border: 2px solid #285e8e; /*set the color you want here*/
  }

  .description {
    font-family: Museo;
    color: #035795;
    font-size: 15px;
  }

  .contact-description {
    max-width: 1000px;
    margin: auto;
  }

  form {
    /* width: 80%; */
    color: rgb(126, 126, 126);
    margin-left: 30px;
    margin-right: 30px;
  }

  .form-group {
    margin-top: 20px;
  }

  .career-form {
    margin-bottom: 100px;
  }

  .form-control {
    border: 2px solid #035795 !important;
    border-radius: 10px;
  }

  .btn-primary {
    border-radius: 50px;
    background-color: #035795;
    border: 2px solid #285e8e;
    width: 200px;
    margin-top: 30px;
    float: right;
    font-family: Museo-bold;
  }

  .spin {
    float: right;
    margin-top: 30px;
  }

  .contact-inquiries {
    color: #035795;
    font-size: 15px;
    font-family: Museo-bold;
  }

  .contact-desc {
    color: rgb(110, 110, 110);
    font-size: 15px;
    font-family: Museo-thin;
  }

  .address {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  input[type='text'] {
    height: 40px;
  }
  input[type='number'] {
    height: 40px;
    -moz-appearance: textfield;
  }

  input {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  label {
    font-size: 15px;
  }

  @media only screen and (min-width: 768px) and (max-width: 993px) {
    .career-form {
      min-width: 100%;
    }

    .career-intro {
      min-width: 100%;
      text-align: center;
    }
  }
  @media only screen and (min-width: 993px) {
    .vl {
      position: relative;
      left: 70px;
      border-left: 2px solid #035795;
      height: 400px;
    }
    .career-form {
      min-width: 60%;
    }

    .long-input {
      min-width: 400px;
      height: 30px;
    }

    .contact-section {
      max-width: 1000px;
    }
  }

  @media only screen and (max-width: 993px) {
    .career-form {
      width: 100%;
    }
    .career-intro {
      min-width: 100%;
      text-align: center;
    }
    .contact-description {
      text-align: center;
    }

    .address {
      text-align: center;
    }

    label {
      font-size: 10px;
    }
  }

  @media only screen and (max-width: 768px) {
    .address {
      text-align: center;
    }

    .career-image {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
</style>
its
