import Vue from 'vue';
import Router from 'vue-router';
import Home from '../pages/Dashboard';
import About from '../pages/AboutUs';
import Manufacturing from '../pages/Manufacturing';
import Quality from '../pages/Quality';
import Rnd from '../pages/Rnd';
import Products from '../pages/Products';
import Careers from '../pages/Careers';
import Contact from '../pages/Contact';
import News from '../pages/News';
import Article from '../pages/Article';
import Slick from '../components/CarouselCard/Slick';
// import ReadyToMeal from '../components/ReadyToMeal';

Vue.use(Router);

export default new Router({
  // mode: 'history',
  routes: [
    {
      path: '*',
      name: 'home',
      component: Home,
    },
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/about',
      name: 'about',
      component: About,
    },
    {
      path: '/manufacturing',
      name: 'manufacturing',
      component: Manufacturing,
    },
    {
      path: '/quality',
      name: 'quality',
      component: Quality,
    },
    {
      path: '/rnd',
      name: 'rnd',
      component: Rnd,
    },
    {
      path: '/careers',
      name: 'careers',
      component: Careers,
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact,
    },
    // {
    //   path: '/products',
    //   name: 'product',
    //   component: Products,
    // },
    {
      path: '/products/:page/:section',
      name: 'product',
      component: Products,
    },
    {
      path: '/products/:page',
      name: 'product',
      component: Products,
    },
    {
      path: '/news',
      name: 'news',
      component: News,
    },
    {
      path: '/news/:id',
      name: 'newsDetail',
      component: Article,
    },
    {
      path: '/slick',
      name: 'slick',
      component: Slick,
    },
  ],
  scrollBehavior(to) {
    if (
      to.path.includes('tastesolutions/') ||
      to.path.includes('tradingpartners/') ||
      to.path.includes('menusolutions/') ||
      to.path.includes('naturalsolutions/')
    ) {
      return {};
    } else {
      return { x: 0, y: 0 };
    }
  },
});
