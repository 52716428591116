<template>
  <div>
    <!-- <Dashboard /> -->
    <router-view :key="$route.fullPath"></router-view>
    <div @click="scrollToTop()" class="back-to-top">
      <img class="my-float" src="./assets/Dashboard/arrowup.png" />
    </div>
    <a href="https://wa.me/+6281210150201" class="float" target="_blank">
      <img class="my-float" src="./assets/Dashboard/whatsapp-icon.png" />
    </a>
  </div>
</template>

<script>
  // import HelloWorld from './components/HelloWorld.vue';
  // import Dashboard from './components/Pages/Dashboard.vue';

  export default {
    name: 'App',
    components: {
      // HelloWorld,
      // Dashboard,
    },
    methods: {
      scrollToTop() {
        window.scroll({
          top: 0,
          behavior: 'smooth',
        });
      },
    },
  };
</script>

<style scoped>
  /* #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  } */

  .float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 20px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
  }

  .back-to-top {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 120px;
    right: 20px;
    background-color: #999;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
  }

  .my-float {
    margin-top: 16px;
    z-index: 1;
  }

  img {
    width: 30px;
    height: 30px;
    position: relative;
    bottom: 4px;
  }
</style>
