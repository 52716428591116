<template>
  <div>
    <div class="natural-solutions">
      <div id="meat-extracts" class="main-title text-center natural-content">
        <div
          class="row justify-content-center align-items-center natural-title-mobile"
        >
          <img class="flower col-3" src="../../assets/Dashboard/flower.png" />
          <div class="ready-title col-6 text-center">
            Meat Extracts
          </div>
          <img class="flower col-3" src="../../assets/Dashboard/flower.png" />
        </div>
        <div class="col natural-title-desktop">
          <img class="flower" src="../../assets/Dashboard/flower.png" />
          <span class="ready-title">Meat Extracts</span>
          <img class="flower" src="../../assets/Dashboard/flower.png" />
        </div>
        <div class="description">
          <p class="natural-text">
            Foodex has been developing meat extraction technology since 2000 and
            is now one of renowned high quality meat extract manufacturer in
            Indonesia. Our meat extract series are suitable for application in
            various industries especially instant noodle, seasoning blenders,
            snack, saunce as well as meat processing companies. They can impart
            meatiness and richness in flavor and mouth feel, as well ass
            contributing positively to nutrition profile of your food products.
          </p>
          <p>
            For convenience in your application, we offer these product in
            powder, paste and fat/oil form.
          </p>
        </div>
      </div>
      <div class="extract-container natural-content">
        <div v-for="extract in extracts" :key="extract.title" class="extracts">
          <div class="row justify-content-center extract align-items-center">
            <img class="col-md-6 extract-image" :src="extract.image" />
            <div class="extract-text col-md-6 ">
              <h1>{{ extract.title }}</h1>
              <p v-html="extract.description"></p>
            </div>
          </div>
        </div>
      </div>
      <img class="mid-image" src="../../assets/Products/natural-parallax.jpg" />
      <div class="parallax"></div>
      <div id="flavor-ingredients">
        <div
          class="row justify-content-center align-items-center natural-title-mobile"
        >
          <img class="flower col-3" src="../../assets/Dashboard/flower.png" />
          <div class="ready-title col-6 text-center">
            Flavour Ingredients
          </div>
          <img class="flower col-3" src="../../assets/Dashboard/flower.png" />
        </div>
        <div class="col text-center natural-title-desktop">
          <img class="flower" src="../../assets/Dashboard/flower.png" />
          <span class="ready-title">Flavour Ingredients</span>
          <img class="flower" src="../../assets/Dashboard/flower.png" />
        </div>
      </div>
      <div class="description text-center natural-content">
        <p class="natural-text">
          At foodex ingredients, we are pleased to offer extensive line of
          dehydrated vegetables and spices as well as savory enhancers and solid
          ingredients to meet all your application needs.
        </p>
        <p class="natural-text">
          Our flavor ingredients are made from natural ingredients, delivering
          nice and balanced flavor profile in your finished products. They are
          suitable for dry seasoning mix, sauces,soups and can be combined in
          creative ways into your formulation.
        </p>
      </div>
      <div class="extract-container natural-content">
        <div v-for="extract in extracts2" :key="extract.title" class="extracts">
          <div class="row justify-content-center extract align-items-center">
            <img class="col-md-6 extract-image" :src="extract.image" />
            <div class="extract-text col-md-6">
              <h1>{{ extract.title }}</h1>
              <p v-html="extract.description"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="parallax2"></div>
      <img
        class="mid-image"
        src="../../assets/Products/natural-parallax2.jpg"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NaturalSolutions',
    props: ['subsection'],
    watch: {
      subsection(section) {
        if (section) {
          var elem = document.getElementById(section);
          elem.scrollIntoView();
          window.scrollTo(0, 10);
        }
        this.$emit('clearSection');
      },
    },
    mounted() {
      if (this.$route.params.section) {
        var elem = document.getElementById(this.$route.params.section);
        elem.scrollIntoView(true);
        elem.scrollTop -= 10;
      } else {
        document.body.scrollTop += 10;
      }
      this.$emit('clearSection');
    },
    data() {
      return {
        extracts: [
          {
            title: 'Chicken Products Range',
            description: `<p>Our products range from chicken fat pure natural chicken extract, to flavored chicken extract eith profile such as white meat, thigh, boiled,meaty and roasted.</p>
            <p>Our chicken extract products are suitable as base for Instant noodle and soul seasoning, processed meat, snack and culinary sauce</p>`,
            image: require('../../assets/Products/chicken.png'),
          },
          {
            title: 'Beef Product Range',
            description: `<p>Our beef extract products range from pure beef extract to beef extract with profiles such as roast beef, grilledbeef, stewed beef, and brothy beef.</p>
            <p>Our beef fat has full bodied arome and flavor, contributing to strong beef profile to your food products.<p>
            <p>Our beef extract products are particularly suited for meatball and beef soup broth, sausages, snack seasonings, instant noodle seasonings and other culinary creating which requiring strong beef profile.</p>`,
            image: require('../../assets/Products/cow.png'),
          },
          {
            title: 'Seafood Product Range',
            description: `<p>Our seafood products are available in oil, paste or powder form.
            We guarantee freshness of our seafood raw material source to produce high quality tasty seafood products.</p>`,
            image: require('../../assets/Products/shrimp.png'),
          },
        ],
        extracts2: [
          {
            title: 'Dehydrated vegetables',
            description: `<p>Foodex is specialized ini manufacturing and blending of dehydrated vegetables products particulary in garlic, chilli and onion products. We have extensive product librarty with different flavor profile such as raw, roaster, toasted or fried.</p>
            <p>All products are available ion special blend as per customer specification in term of size, color and taste to make sure they can bring extra value to your food creation.</p>
            <p>In addition to our specialty in garlic, chili and onion products, we also cary variety of dehydrated vegetables, spices and herbs such as white pepper, black pepper, turmeric, tamarind and capsicum.</p>
            `,
            image: require('../../assets/Products/vege.png'),
          },
          {
            title: 'Savory Enhancer',
            description: `<p>Foodex carry a unique postfolitio of savory flavors and flavor enhancers to intensify umami effect and enhance savory and heary note in the food applications. Our products such as Hydrolyzed Vegetable Protein(HVP), 
            Soy Sauce Powder (SSP),Savory Booster or Yeast Extract are just some examples where you can benefit from our fine collection</p>`,
            image: require('../../assets/Products/savory.png'),
          },
          {
            title: 'Solid Ingredients',
            description: `<p>Foodex has developed solid ingredients such as dried tofu, dried meat balls or dried sausage to compliment your products. They are great for snacks, rice toppings or for 
            reconstitution in instant noodle and instant soup applications.</p>`,
            image: require('../../assets/Products/solid.png'),
          },
          {
            title: 'Paste Ingredients',
            description: `We developed special paste for onion and garlic products range by using natural ingredients. 
            Our is available with unique fried character, designed to give appealing fagrant aroma and delectable flavor of select spices. These oil-based pastes are versatile to use in noodle, saunce, pr processed meat application, and can easily bne plated to make sasoning as well
            A little touch to elevate your product to the next level!`,
            image: require('../../assets/Products/paste.png'),
          },
        ],
      };
    },
  };
</script>

<style scoped>
  .natural-content {
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 30px;
    padding-right: 30px;
  }

  #meat-extracts {
    scroll-margin-top: 7em;
  }

  #flavor-ingredients {
    scroll-margin-top: 7em;
  }

  .parallax {
    background-image: url('../../assets/Products/natural-parallax.jpg');
    min-height: 400px;

    /* Create the parallax scrolling effect */
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-attachment: fixed;
  }

  .parallax2 {
    background-image: url('../../assets/Products/natural-parallax2.jpg');
    min-height: 400px;

    /* Create the parallax scrolling effect */
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    background-attachment: fixed;
  }

  p {
    font-family: Museo-thin;
  }

  .natural-text {
    margin: 0 7rem 0 7rem;
  }

  .ready-title {
    color: #035795;
    font-family: Museo-bold;
  }

  h1 {
    font-family: Museo-bold;
    margin-bottom: 20px;
  }

  .mid-image {
    width: 100%;
  }

  .extracts {
    margin-top: 20px;
    margin-bottom: 30px;
    color: #035795;
  }

  .description {
    margin: auto;
    color: #035795;
  }

  @media screen and (min-width: 768px) {
    .flower {
      position: relative;
      top: -10px;
      margin: 0 30px 0 30px;
      width: 40px;
      height: 100%;
    }

    .description {
      font-size: 15px;
      padding: 2vw;
    }

    .natural-title-desktop {
      margin-top: 50px;
    }

    .extracts {
      margin-top: 20px;
      margin-bottom: 30px;
      font-size: 15px;
      padding: 2vw;
      color: #035795;
    }

    h1 {
      font-size: 35px;
    }

    .natural-title-mobile {
      display: none;
    }

    .ready-title {
      font-size: 35px;
    }

    .mid-image {
      display: none;
    }

    .extract-image {
      width: auto;
      margin-bottom: 30px;
      height: 150px;
    }
  }

  @media only screen and (max-width: 767px) {
    .extract-image {
      width: auto;
      margin-bottom: 30px;
      height: 150px;
    }

    .mid-image {
      min-height: 250px;
      object-fit: cover;
      display: block;
    }

    .natural-content {
      padding-left: 10px;
      padding-right: 10px;
    }

    .description {
      margin-top: 30px;
    }

    .extract {
      padding: 15px;
    }
    .extract-container {
      margin-bottom: 50px;
    }
    .ready-title {
      font-size: 25px;
    }

    .flower {
      width: 60px;
      height: 100%;
    }

    .natural-title-mobile {
      margin-top: 50px;
    }

    .natural-title-desktop {
      display: none;
    }

    .extract-text {
      text-align: center;
    }

    .natural-text {
      margin: 0 2rem 0 2rem;
      font-size: 15px;
    }
    .parallax {
      display: none;
    }

    .parallax2 {
      display: none;
    }
  }
</style>
