<template>
  <div class="dashboard">
    <Navbar />
    <Carousel />
    <About class="about" />
    <VisionMission />
    <Products />
    <Highlights />
    <Partners />
    <Footer />
    <!-- <modal-popup /> -->
  </div>
</template>

<script>
  import Navbar from '../components/Navbar.vue';
  import Carousel from '../components/Carousel.vue';
  import About from '../components/About.vue';
  import VisionMission from '../components/VisionMission.vue';
  import Products from '../components/Products.vue';
  import Highlights from '../components/Highlights.vue';
  import Partners from '../components/Partners.vue';
  import Footer from '../components/Footer.vue';
  // import ModalPopup from '../components/Modal.vue';

  export default {
    name: 'App',
    components: {
      About,
      Navbar,
      Carousel,
      VisionMission,
      Products,
      Highlights,
      Partners,
      Footer,
      // ModalPopup,
    },
  };
</script>

<style>
  .dashboard {
    overflow-x: hidden;
    overflow-y: hidden;
    font-family: Museo;
  }

  @font-face {
    font-family: Museo;
    src: url(../assets/fonts/Museo500-Regular.otf);
  }

  @font-face {
    font-family: Museo-bold;
    src: url(../assets/fonts/Museo700-Regular.otf);
  }

  @font-face {
    font-family: Museo-thin;
    src: url(../assets/fonts/Museo300-Regular.otf);
  }

  @font-face {
    font-family: Museo-bolder;
    src: url(../assets/fonts/Museo900-Regular.otf);
  }
</style>
