<template>
  <div>
    <div v-if="bannerImage !== 'mobile'">
      <div
        class="jumbotron jumbotron-left"
        v-if="position === 'left'"
        v-bind:style="backgroundImg"
      >
        <div class="jumbotron-content left fade-in-title">
          <h1 class="display-4 title title-left">{{ title }}</h1>
          <p class="description description-left">
            {{ description }}
          </p>
        </div>
      </div>

      <div
        class="jumbotron"
        v-else-if="position === 'middle'"
        v-bind:style="backgroundImg"
      >
        <div class="jumbotron-content-middle middle text-center fade-in-title">
          <div class="middle-content">
            <h1 class="display-4 title title-middle">{{ title }}</h1>
            <p class="description-middle">
              {{ description }}
            </p>
          </div>
        </div>
      </div>

      <div
        class="jumbotron jumbotron-right row justify-content-end "
        v-else-if="position === 'right'"
        v-bind:style="backgroundImg"
      >
        <div class="jumbotron-content right fade-in-title">
          <h1 class="display-4 title title-right">{{ title }}</h1>
          <p class="description description-right">
            {{ description }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="jumbotron jumbotron-mobile-middle"
      v-else-if="bannerImage === 'mobile' && mobileMiddle"
      v-bind:style="mobileImage"
    >
      <div
        class="jumbotron-content mobile text-center"
        :style="{ color: '#035795' }"
      >
        <h1 class="display-4 title">{{ title }}</h1>
        <p class="description">{{ description }}</p>
      </div>
    </div>
    <div class="jumbotron jumbotron-mobile" v-else v-bind:style="mobileImage">
      <div
        class="jumbotron-content mobile"
        :style="{ color: fontColor ? fontColor : 'white' }"
      >
        <h1 class="display-4 title">{{ title }}</h1>
        <p class="description">
          {{ description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Jumbotron',
    props: [
      'backgroundImg',
      'title',
      'description',
      'position',
      'mobileImage',
      'fontColor',
      'mobileMiddle',
    ],
    data() {
      return {
        windowWidth: window.innerWidth,
        bannerImage: window.innerWidth <= 767 ? 'mobile' : 'desktop',
      };
    },
    created() {
      window.addEventListener('resize', this.screenSizeChange);
    },
    destroyed() {
      window.removeEventListener('resize', this.screenSizeChange);
    },
    methods: {
      screenSizeChange() {
        if (window.innerWidth <= 767) {
          this.bannerImage = 'mobile';
        } else this.bannerImage = 'desktop';
      },
    },
  };
</script>

<style scoped>
  .title {
    font-size: 35px;
    font-family: Museo-bold;
  }

  .jumbotron {
    padding-top: 20%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .jumbotron-left {
    padding-left: 7%;
    font-family: Museo;
  }

  .jumbotron-mobile-middle {
    padding-top: 50%;
  }

  .jumbotron-right {
    padding-left: 7%;
    font-family: Museo;
  }

  .middle {
    color: #035795;
    position: relative;
    /* bottom: 2rem; */
    font-family: Museo-bold;
  }

  .middle-content {
    /* margin: 0; */
    position: relative;
    top: 10%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin-left: 30px;
    margin-right: 30px;
  }

  .mobile-middle {
    padding-top: 50%;
  }

  .description-middle {
    font-size: 20px;
    font-family: Museo-thin;
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
  }

  .description {
    font-size: 20px;
  }

  .jumbotron-content-middle {
    height: 320px;
    position: relative;
    /* top: 10px; */
  }

  @media only screen and (min-width: 1200px) {
    .jumbotron-content {
      min-height: 400px;
    }
    .left {
      position: relative;
      vertical-align: middle;
      width: 35%;
      color: white;
      font-family: Museo-thin;
      transform: translate(0%, -30%);
    }

    .right {
      transform: translateX(-15%);
      position: relative;
      top: -2rem;
      left: -5rem;
      width: 45%;
      color: #035795;
      font-family: Museo-thin;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1200px) {
    .title-middle {
      padding-top: 100px;
    }

    .jumbotron-content {
      min-height: 350px;
    }

    .title-left {
      font-size: 30px;
    }

    .description-left {
      font-size: 15px;
    }

    .title-right {
      font-size: 30px;
    }

    .description-right {
      font-size: 15px;
    }

    .left {
      position: relative;
      width: 38%;
      color: white;
      font-family: Museo-thin;
      transform: translate(0%, -30%);
    }

    .right {
      position: relative;
      top: -3rem;
      left: -4rem;
      width: 45%;
      transform: translate(-15%, -40%);
      color: #035795;
      font-family: Museo-thin;
    }
  }

  @media only screen and (max-width: 767px) {
    .jumbotron {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      font-family: Museo;
    }

    .jumbotron-mobile {
      height: 170vw;
    }
    .jumbotron-mobile-middle {
      height: 120vw;
    }

    .mobile .title {
      margin-top: 20px;
      font-size: 25px;
    }

    .mobile {
      margin: 0 20px 0 20px;
    }

    .mobile .description {
      font-size: 15px;
    }
  }

  @keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-60px);
      transform: translateY(-60px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  .fade-in-title {
    -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-top 2s cubic-bezier(0.1, 0.575, 0.565, 1) both;
  }
</style>
