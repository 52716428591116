<template>
  <div class="about-us">
    <Navbar />
    <Jumbotron
      :backgroundImg="background"
      :mobileImage="mobileBackground"
      :title="title"
      :position="'left'"
      :description="description"
    />
    <div class="about-us-content">
      <div class="first row align-items-center">
        <img
          src="../assets/About/about-us-1.png"
          class="about-image col-md-7 order-1 order-sm-12 order-md-0 order-xl-0"
        />
        <div class="about-text col-md-5 col-sm-12 align-self-center">
          <h1>From Local to Global</h1>
          <p>
            Established in 1995m PT Foodex Inti Ingredients is one of the
            leading manufacturers of food seasoning and food ingredients from
            Indonesia. Our main focus is to be a reliable partner and to provide
            solution for wide selection of food industries such as
            <b>Noodle, Snack, Processed Meat and Food Service Industry.</b> We
            provide solutions through our expertise in six product categories:
            Seasonings, Meat Extracts, Flavor Ingredients, Functional
            Ingredients, Sauce & Condiments and Ready-To-Eat products.
          </p>
        </div>
      </div>
      <div class="about-text second row align-items-center">
        <div
          class="col-md-5 col-sm-12 align-self-center order-sm-0 order-md-0 order-xl-0"
        >
          <p class="first-ph">
            In 2012, we expanded our business by upgrading our facility and
            improving our capacity and technology. Our new factory, located in
            Kawasan Industri Delta Silicon 3, Lippo Cikarang, Bekasi, has
            evolved dramatically with complete and modern state of the art
            machines. Combined with our customer focused apporach and technical
            expertise, we are ready to provide solution to our customer needs
            anywhere in the world.
          </p>
          <p class="second-ph">
            2020 marked our 25h years of partnership also it was the year where
            we obtained FSSC 22000:2005 certificate. Those accomplishments are
            to show that we are serious and committed to be the leader for
            Seasonings and Ingredients. We are committed to providing consistent
            quality and tasty products.
          </p>
        </div>
        <img
          class="about-image col-md-7 col-sm-12 order-sm-0 order-md-1"
          src="../assets/About/about-us-2.png"
        />
      </div>
    </div>
    <div class="license license-mobile">
      <p class="cert-text text-center">
        Our manufacturing and warehouse facility adhere to HACCP, GMP and FSSC
        22000 food safety & management system.
      </p>
      <div class="cert row justify-content-center">
        <div class="cert-content text-center col-md-3 col-sm-12">
          <img class="cert-image" src="../assets/About/gmpcert.png" />
          <p>(GMP) Good Manufacturing Practices</p>
        </div>
        <div class="cert-content text-center col-md-3 col-sm-12">
          <img class="cert-image" src="../assets/About/muicert.png" />
          <p>Halal Assurance System by MUI</p>
        </div>
        <div class="cert-content text-center col-md-3 col-sm-12">
          <img class="cert-image" src="../assets/About/fssccert.png" />
          <p>FSSC 22000: 2005</p>
        </div>
      </div>
    </div>
    <ColorfulOptions />
    <Footer />
  </div>
</template>

<script>
  import Navbar from '../components/Navbar.vue';
  import Jumbotron from '../components/Jumbotron.vue';
  import ColorfulOptions from '../components/ColorfulOptions.vue';
  import Footer from '../components/Footer.vue';

  export default {
    name: 'AboutUs',
    components: { Navbar, Jumbotron, ColorfulOptions, Footer },
    data() {
      return {
        background: {
          backgroundImage: `url(${require('../assets/About/about-us-banner.png')})`,
        },
        mobileBackground: {
          backgroundImage: `url(${require('../assets/About/about-us-banner-mobile.png')})`,
        },
        title: 'The Leading Manufacturing for Food Seasonings and Ingredients',
        description:
          'Foodex Intl Ingredients has relocated and completed expansion of processing and warehousing center to meet the increasing requirement of our local and international customers',
        // windowWidth: window.innerWidth,
      };
    },
    mounted() {
      document.body.scrollTop += 10;
    },
  };
</script>

<style scoped>
  .about-us {
    font-family: Museo;
    color: #035795;
    overflow-x: hidden;
  }

  h1 {
    font-family: Museo-bold;
  }

  .about-text {
    margin-top: 50px;
    margin-bottom: 50px;
    font-family: Museo-thin;
  }

  .about-us-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1100px;
    padding: 20px;
  }

  .first-ph {
    color: #c78947;
  }

  .license {
    background-color: #e9e9e9;
    margin-bottom: 200px;
  }

  .cert-image {
    margin-bottom: 10px;
  }

  @media only screen and (min-width: 768px) {
    .about-us-content {
      padding: 15px;
      /* margin: 0 15px 0 15px; */
      font-size: 15px;
    }

    .about-image {
      height: 100%;
    }

    .cert {
      position: relative;
      top: 130px;
    }

    .cert-text {
      position: relative;
      top: 80px;
      margin: 0 50px 0 50px;
      font-size: 18px;
      padding-top: 20px;
    }

    .cert-content {
      margin-left: 30px;
      margin-right: 30px;
    }
  }

  @media only screen and (max-width: 767px) {
    .cert-text {
      font-size: 18px;
      padding-top: 30px;
      margin: 0 30px 30px 30px;
    }

    .about-us-content {
      text-align: center;
    }

    .first-ph {
      padding: 15px;
    }

    .second-ph {
      padding: 15px;
    }

    .about-image {
      width: 100%;
    }

    .cert-content {
      margin-top: 30px;
    }
  }
</style>
