<template>
  <div class="rnd">
    <Navbar />
    <Jumbotron
      :backgroundImg="background"
      :mobileImage="mobileBackground"
      :title="title"
      :position="'left'"
      :description="description"
    />
    <div class="rnd-content">
      <div>
        <p class="text-center rnd-text">
          We believe in continuous development to guarantee our customers a
          competitive edge through great quality and great competitiveness. Our
          innovation effort focused on three pillars:
        </p>
        <div
          class="pillars row text-center justify-content-center align-items-center"
        >
          <div class="pillar col-xl-3 col-lg-3 col-md-12 col-sm-12">
            <span class="dot">1</span>
            <h3>Development</h3>
            <p>of great application concept for the customers</p>
          </div>
          <div class="pillar col-xl-3  col-lg-3 col-md-12 col-sm-12">
            <span class="dot">2</span>
            <h3>Market Trend</h3>
            <p>Method being used while we are making New product development</p>
          </div>
          <div class="pillar col-xl-3  col-lg-3 col-md-12 col-sm-12">
            <span class="dot">3</span>
            <h3>Customization</h3>
            <p>
              requests as well as product optimization and technical support.
            </p>
          </div>
        </div>
      </div>
      <div class="align-self-center text-center">
        <div class="rnd-text">
          <p>
            We've gone through TRIPLE CHECK SYSTEM to guarantee the consistency
            & standard products
          </p>
          <p>
            To support product development and commercialization, our
            state-of-the art Pilot Plant and Application Center is available to
            our current and potential customers
          </p>
        </div>
      </div>
      <div class="first row">
        <img src="../assets/Rnd/rnd-1.png" class="rnd-image col-md-7" />
        <div class="col-md-5 align-self-center">
          <div class="rnd-text">
            In this Center, our customers can collaborate with our team to
            develop the answer to their requirements and finalize development
            projects before it is being commercialized. Leveraging on our
            sourcing expertise, technical knowledge of our product development
            team and collaboration with chefs and research bodies, Foodex Inti
            Ingredients can create astonishing variety, flavor and functionality
            in your end product.
          </div>
        </div>
      </div>
    </div>
    <ColorfulOptions />
    <Footer />
  </div>
</template>

<script>
  import Navbar from '../components/Navbar.vue';
  import Jumbotron from '../components/Jumbotron.vue';
  import ColorfulOptions from '../components/ColorfulOptions.vue';
  import Footer from '../components/Footer.vue';

  export default {
    name: 'Rnd',
    components: { Navbar, Jumbotron, ColorfulOptions, Footer },
    data() {
      return {
        background: {
          backgroundImage: `url(${require('../assets/Rnd/rnd-banner.png')})`,
        },
        mobileBackground: {
          backgroundImage: `url(${require('../assets/Rnd/rnd-mobile-banner.png')})`,
        },
        title: 'Research & Development',
        description:
          'With a strong focus on R&D, Foodex Inti Ingredients is on the forefront to provide innovative food ingredient solutions.',
      };
    },
    mounted() {
      document.body.scrollTop += 10;
    },
  };
</script>

<style scoped>
  .rnd {
    font-family: Museo;
    color: #035795;
    overflow-x: hidden;
  }

  .rnd-text {
    margin: 1rem 0 2rem 0;
  }

  .rnd-image {
    height: 100%;
  }

  .first-ph {
    color: #c78947;
  }

  .third-ph {
    /* margin: auto; */
    padding: 50px 0 50px 0;
    width: 60%;
  }

  .pillar {
    color: #c78947;
    border: 2px solid #c78947;
    min-width: 250px;
    min-height: 200px;
    max-width: 250px;
    border-radius: 30px;
    margin: 20px;
    padding: 0 30px 0 30px;
  }

  .dot {
    position: relative;
    top: -20px;
    height: 40px;
    width: 40px;
    background-color: #035795;
    color: white;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    padding-top: 10px;
  }

  /* .pillars {
    margin: 20px;
  } */

  @media only screen and (max-width: 767px) {
    .rnd-content {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 100px;
      padding: 15px;
    }
  }

  @media only screen and (min-width: 768px) {
    .rnd-content {
      padding: 5vw;
      font-size: 15px;
      max-width: 1100px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  @media only screen and (max-width: 992px) {
    .pillars {
      max-width: 350px;
      margin: auto;
    }
  }
</style>
