<template>
  <div>
    <div
      id="carouselExampleControls"
      class="carousel slide carousel-mobile"
      data-interval="false"
    >
      <div class="carousel-inner">
        <div
          v-for="item in content"
          :key="item.title"
          v-bind:class="{ active: item.active }"
          class="carousel-item"
        >
          <div class="carousel-content">
            <h1>{{ item.title }}</h1>
            <p>{{ item.description }}</p>
            <router-link :to="item.route">
              <button class="btn btn-primary">Learn More</button>
            </router-link>
          </div>

          <img
            :src="item.mobile"
            class="d-block w-100 carousel-image"
            alt="..."
          />
        </div>
      </div>
      <img
        src="../../assets/Dashboard/left-arrow.png"
        class="carousel-control-prev button-arrow"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="prev"
      />
      <img
        src="../../assets/Dashboard/right-arrow.png"
        class="carousel-control-next button-arrow"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="next"
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CarouselCardMobile',
    props: ['content'],
    data() {
      return {
        carouselContents: [
          {
            title: 'Trading Partners',
            active: true,
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed d eiusmod tempor incididunt ut labore et dolore magna.',
            image: require('../../assets/Dashboard/product-mobile1.png'),
          },
          {
            title: 'Natural Solution',
            active: false,
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed d eiusmod tempor incididunt ut labore et dolore magna.',
            image: require('../../assets/Dashboard/product-mobile2.png'),
          },
          {
            title: 'Menu Solutions',
            active: false,
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed d eiusmod tempor incididunt ut labore et dolore magna.',
            image: require('../../assets/Dashboard/product-mobile3.png'),
          },
          {
            title: 'Taste Solutions',
            active: false,
            description:
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed d eiusmod tempor incididunt ut labore et dolore magna.',
            image: require('../../assets/Dashboard/product-mobile4.png'),
          },
        ],
      };
    },
  };
</script>

<style scoped>
  .carousel-mobile {
    margin-top: 50px;
  }

  .carousel-image {
    height: 500px;
  }

  .carousel-content {
    position: absolute;
    top: 30px;
    color: white;
    text-align: center;
  }

  .btn-primary {
    background-color: white;
    color: #035795;
    border: none;
    padding: 8px 35px;
    border-radius: 30px;
  }

  .button-arrow {
    position: absolute;
    top: 220px;
  }

  p {
    font-size: 15px;
    margin-right: 10px;
    margin-left: 10px;
  }
</style>
