<template>
  <div class="footer container-fluid">
    <div class="footer-content row">
      <ul class="footer-list col-lg-4 col-md-4 col-sm-6 col-6">
        <router-link to="/about"><li>Our story</li></router-link>
        <router-link to="/products"><li>Our Products</li></router-link>
        <router-link to="/news"><li>News & Update</li></router-link>
        <router-link to="/contact"><li>Contact Us</li></router-link>
        <router-link to="/careers"><li>Careers</li></router-link>
      </ul>
      <div class="col-md-4 col-lg-4 col-2 col-sm-6 col-6">
        <p>Follow us in</p>
        <!-- <img class="logo-soc" src="../assets/Social-01.png" /> -->
        <a target="_blank" href="https://www.instagram.com/foodex_ingredients/">
          <img class="logo-soc" src="../assets/Social-02.png" />
        </a>
        <a
          target="_blank"
          href="https://www.linkedin.com/company/foodex-ingredients/"
        >
          <img class="logo-soc" src="../assets/Social-03.png" />
        </a>
      </div>
      <div class="reserved col-md-4 col-lg-4 col-12">
        PT. Foodex Indonesia All rights reserved
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Footer',
  };
</script>

<style scoped>
  .footer {
    background: #035795;
    font-family: Museo-thin;

    /* height: 500px; */
    /* height: 300px; */
  }

  .footer-content {
    color: white;
    padding: 5%;
    width: 100%;
    height: 100%;
    font-size: 15px;
    max-width: 1200px;
    margin: auto;
  }

  .footer-list a {
    color: inherit;
    max-width: 1200px;
  }

  .logo-soc {
    width: 3rem;
    height: 3rem;
    margin-right: 10px;
  }

  .row {
    --bs-gutter-x: 0;
  }

  .footer-list {
    list-style-type: none;
  }

  @media only screen and (max-width: 500px) {
    .logo-soc {
      width: 2rem;
      height: 2rem;
      margin-right: 10px;
    }
  }

  /* .reserved {
    position: relative;
    top: 90px;
  } */
</style>
