<template>
  <div class="products">
    <div class="stacked-carousel-title">
      <h3>Our Products</h3>
      <h1 class="stacked-carousel-h1">Foodex Solutions</h1>
    </div>
    <div class="stacked-carousel-mobile">
      <CarouselCardMobile :content="carouselContents" />
    </div>
    <div class="stacked-carousel-card">
      <slick :products="carouselContents" />
    </div>
  </div>
</template>

<script>
  import CarouselCardMobile from './CarouselCard/CarouselCardMobile.vue';
  import Slick from './CarouselCard/Slick.vue';
  import '../styles/stacked-carousel.css';

  export default {
    name: 'Products',
    components: {
      CarouselCardMobile,
      Slick,
    },
    props: ['isActive'],
    data() {
      return {
        carouselContents: [
          {
            title: 'Trading Partners',
            description:
              'PT Foodex Inti Ingredients also collaborates with several selected global partners. The sole distributorship in Indonesia is to give a comprehensive solution to its customers.',
            image: require('../assets/Dashboard/Desktop_Carousel1.png'),
            background: {
              backgroundImage: `url(${require('../assets/Dashboard/Desktop_Carousel1.png')})`,
            },
            mobile: require('../assets/Dashboard/product-mobile1.png'),
            route: '/products/tradingpartners',
            active: true,
          },
          {
            title: 'Natural Solution',
            description:
              'Developed through modern and advanced process, also combined with out attention to quality and cost-effective Raw Materials, our Natural Products offer high performance to your application.',
            image: require('../assets/Dashboard/Desktop_Carousel2.png'),
            background: {
              backgroundImage: `url(${require('../assets/Dashboard/Desktop_Carousel2.png')})`,
            },
            route: '/products/naturalsolutions',
            mobile: require('../assets/Dashboard/product-mobile2.png'),
          },
          {
            title: 'Menu Solutions',
            description:
              'With a stong focus on R&D, Foodex Inti Ingredients is on the forefront to provide innovatigve food ingredients solutions.',
            image: require('../assets/Dashboard/Desktop_Carousel3.png'),
            background: {
              backgroundImage: `url(${require('../assets/Dashboard/Desktop_Carousel3.png')})`,
            },
            route: '/products/menusolutions',
            mobile: require('../assets/Dashboard/product-mobile3.png'),
          },
          {
            title: 'Taste Solutions',
            description:
              'Foodex Inti Ingredients is highly specialized in formulating seasoning blends containing spices, herbs and other flavor/functional ingredients to create unique and flavorful recipes',
            image: require('../assets/Dashboard/Desktop_Carousel4.png'),
            background: {
              backgroundImage: `url(${require('../assets/Dashboard/Desktop_Carousel4.png')})`,
            },
            route: '/products/tastesolutions',
            mobile: require('../assets/Dashboard/product-mobile4.png'),
          },
        ],
      };
    },
    created() {},
  };
</script>

<style scoped>
  .stacked-carousel-mobile {
    display: none;
  }

  .stacked-carousel-button {
    padding: 10px;
    width: 200px;
    border: none;
    border-radius: 20px;
    color: #035795;
    box-shadow: 1px 1px 2px 1px grey;
  }

  .products {
    background-image: url('../assets/Dashboard/product-home-background.png');
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;
    /* height: 900px; */
  }

  .stacked-carousel-title {
    text-align: center;
    padding-top: 80px;
  }

  h3 {
    font-size: 25px;
    color: #d40833;
  }

  .stacked-carousel-h1 {
    font-size: 40px;
    color: #035795;
  }
  .stacked-carousel-card {
    padding-top: 2vw;
    padding-bottom: 2vw;
  }

  @media only screen and (min-width: 992px) {
    .carousel-card-image {
      width: 96%;
      height: auto;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {
    .stacked-carousel-button {
      padding: 5px !important;
      width: 100px !important;
    }

    .carousel-card-image {
      width: 98%;
      height: auto;
    }
  }

  @media only screen and (max-width: 767px) {
    .products {
      background-image: url('../assets/Dashboard/product-mobile-background.png') !important;
    }
    .stacked-carousel-mobile {
      display: block;
    }

    .stacked-carousel-card {
      display: none;
    }
    h3 {
      font-size: 20px;
    }

    .stacked-carousel-h1 {
      font-size: 30px;
    }
  }
</style>
