<template>
  <div id="ready-to-meal" class="ready-to-meal">
    <div class="text-center description meal-content">
      <div class="main-title text-center">
        <div
          class="row justify-content-center align-items-center menu-title-mobile"
        >
          <img class="flower col-4" src="../../assets/Dashboard/flower.png" />
          <div class="ready-title col-6 text-center">
            Ready To Meal
          </div>
          <img class="flower col-4" src="../../assets/Dashboard/flower.png" />
        </div>
        <div class="col menu-title-desktop">
          <img class="flower" src="../../assets/Dashboard/flower.png" />
          <span class="ready-title">Ready To Meal</span>
          <img class="flower" src="../../assets/Dashboard/flower.png" />
        </div>
      </div>

      <div class="description-text">
        <p>
          In the modern era, lifestyle of consumers become increasingly hectic
          due to economic and social growth and they have less time on hands to
          prepare meals from scratch. Consumers sough easy and convenient meal
          options to save time. To answer the market demand. foodex has creted
          specialized until to cater and develop customized ready-to-eat meal.
        </p>
        <p>
          Packed in retort pouches and processed with modern sterilization
          technologym these ready-to-eat meals are ready to use as is or can
          simply be heated for 3-5 minutes to enjoy delicious and hygienic
          foods. Our products are free from addition of preservatives and can
          have shelf life for up to 12 months in room temperature.
        </p>
      </div>
    </div>
    <div class="parallax"></div>
    <img class="mid-image" src="../../assets/Products/menu-parallax.jpg" />
    <div class="description-text text-center meal-content">
      <p>
        Foodex Inti Ingredients undertake contact manufacturing and development
        of ready-to-meal meals according to our customers' requirement
        encompasing variety of dishes including porridge, rice, soup, side dish
        and dessert. In addition, our products also offer convenience to food
        service, fast food chain outlets, institutional, catering, disaster
        foods aids, Hajj pilgrimage, military rations as well as processed food
        industries.
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ReadyToMeal',
    props: ['subsection'],
    watch: {
      subsection(section) {
        if (section) {
          var elem = document.getElementById(section);
          elem.scrollIntoView();
        }
        this.$emit('clearSection');
      },
    },
    mounted() {
      if (this.$route.params.section) {
        var elem = document.getElementById(this.$route.params.section);
        elem.scrollIntoView();
      } else {
        document.body.scrollTop += 10;
      }
      this.$emit('clearSection');
    },
  };
</script>

<style scoped>
  #ready-to-meal {
    scroll-margin-top: 7em;
  }

  .mid-image {
    width: 100%;
  }

  .main-title {
    margin-top: 30px;
  }

  .flower {
    width: auto;
    height: 50px;
  }

  .description-text {
    margin: auto;
    color: #035795;
  }

  .meal-content {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
  }

  @media only screen and (min-width: 768px) {
    .flower {
      position: relative;
      top: -10px;
      /* margin: 0 30px 0 30px; */
      width: 40px;
      height: 100%;
    }
    .ready-title {
      font-size: 35px;
      color: #035795;
      font-family: Museo-bold;
      padding: 0 20px 0 20px;
    }
    .parallax {
      background-image: url('../../assets/Products/menu-parallax.jpg');
      min-height: 400px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      background-attachment: fixed;
      display: block;
    }
    .mid-image {
      display: none;
    }

    .menu-title-mobile {
      display: none;
    }

    .menu-title-desktop {
      margin-top: 50px;
    }

    .description-text {
      font-size: 15px;
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }

  @media only screen and (max-width: 767px) {
    .menu-title-desktop {
      display: none;
    }

    .flower {
      width: 60px;
      height: 100%;
    }

    .ready-title {
      font-size: 25px;
      color: #035795;
      font-family: Museo-bold;
      padding: 0 10px 0 10px;
    }
    .parallax {
      display: none;
    }

    .description-text {
      font-size: 15px;
      margin-top: 30px;
      margin-bottom: 50px;
      /* padding: 30px; */
    }
  }
</style>
