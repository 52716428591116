<template>
  <div class="careers">
    <navbar />
    <Jumbotron
      :backgroundImg="background"
      :mobileImage="mobileBackground"
      :mobileMiddle="true"
      :title="title"
      :position="'middle'"
    />
    <div class="row align-items-center careers-description career-section">
      <img
        class="career-image col-md-6 col-sm-6 col-lg-6 col-xl-6 col-12"
        src="../assets/Careers/careers1.png"
      />
      <p class="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-12 description">
        PT Foodex Indonesia are seeking individuals who are energetics,
        dedicative, creative and committed to work together to give best
        services and solution for our customers. We offer this career
        opportunity to individuals who are eager to be a leader in this industry
      </p>
    </div>
    <div class="row career-section">
      <div class="col-md-3 col-sm-12 career-intro">
        <p class="email-career">
          To Join Foodex Ingredients, please fill out the form with your latest
          CV or Portfolio.
        </p>
        <p class="email-attach">
          We are looking forward to welcoming you joining Foodex team
        </p>
      </div>
      <div class="vl col-md-8 col-sm-12 col-xl-8 col-lg-8 career-form col-12">
        <form>
          <div class="form-group">
            <label>Name</label>
            <input
              type="text"
              class="form-control long-input"
              v-model="name"
              autocomplete="nope"
              :disabled="disabled"
            />
            <label>Gender</label>
            <input
              type="text"
              v-model="gender"
              class="form-control long-input"
              autocomplete="nope"
              :disabled="disabled"
            />
            <label>Birth (Date & Place)</label>
            <input
              type="text"
              v-model="birth"
              class="form-control"
              autocomplete="nope"
              :disabled="disabled"
            />
            <label>E-mail</label>
            <input
              type="text"
              v-model="email"
              class="form-control long-input"
              autocomplete="nope"
              :disabled="disabled"
            />
            <label>Phone</label>
            <input
              v-model="phone"
              type="number"
              class="form-control long-input phone"
              autocomplete="nope"
              :disabled="disabled"
            />
          </div>
          <div class="form-group">
            <label>Please upload your CV</label><br />
            <input
              v-on:change="onFileChange"
              type="file"
              class="form-control-file"
              id="exampleFormControlFile1"
            />
            <p class="help-text">File must be in Pdf and Maximum 2 MB</p>
          </div>
          <p class="warning" v-if="this.error">{{ this.error }}</p>
          <button
            v-if="!loading"
            @click="sendEmail()"
            type="button"
            class="btn btn-primary"
          >
            APPLY NOW
          </button>
          <div v-else class="spinner-border spin" role="status"></div>
        </form>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
  import Navbar from '../components/Navbar.vue';
  import Jumbotron from '../components/Jumbotron.vue';
  import Footer from '../components/Footer.vue';
  import Swal from 'sweetalert2';

  export default {
    name: 'Careers',
    components: { Navbar, Jumbotron, Footer },
    data() {
      return {
        title: 'Career Opportunities',
        background: {
          backgroundImage: `url(${require('../assets/Careers/careers-banner.jpg')})`,
        },
        mobileBackground: {
          backgroundImage: `url(${require('../assets/Careers/careers-banner.jpg')})`,
        },
        name: '',
        gender: '',
        birth: '',
        phone: '',
        email: '',
        error: '',
        loading: false,
        file: '',
        disabled: false,
        fileExtension: '',
      };
    },
    methods: {
      onFileChange(e) {
        const reader = new FileReader();
        if (e.target.files) {
          const cloneTargetFile = [...e.target.files];
          const filetype = cloneTargetFile[0].type;
          const slashIndex = filetype.indexOf('/');
          const fileExt = filetype.slice(slashIndex + 1, filetype.length);

          this.fileExtension = fileExt;
          reader.readAsDataURL(e.target.files[0]);
          reader.onload = () => {
            const base64str = reader.result.split(',')[1];
            const decoded = atob(base64str);
            if (decoded.length > 2000000) {
              this.error = 'Maximum file size is 2 MB';
            } else if (fileExt !== 'pdf') {
              this.error = 'Please upload file in PDF format';
            } else {
              this.file = reader.result;
              this.error = '';
            }
          };
        }
      },
      sendEmail() {
        let validateEmail = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}'); //eslint-disable-line
        if (this.name.length < 3) {
          this.error = 'Name must be more than 2 letters';
        } else if (this.gender.length < 3) {
          this.error = 'Please fill out correct Gender';
        } else if (this.birth.length < 10) {
          this.error = 'Please write correct birth format';
        } else if (this.email.length < 6 || !validateEmail.test(this.email)) {
          this.error = 'Please fill out correct email format';
        } else if (this.phone.length < 7) {
          this.error = 'Please fill out correct phone number';
        } else if (this.file.length === 0) {
          this.error = 'Please upload your resume';
        } else {
          this.error = '';
          this.disabled = true;
          this.loading = true;
          window.Email.send({
            SecureToken: '8299240c-e3fc-4119-9a6f-b3039c7dfce6',
            To: 'hrd@foodexingredients.com',
            From: 'smtp.foodexin@gmail.com',
            Subject: `Job Application from ${this.name}`,
            Body: `
              <b>Name</b>: ${this.name} <br/>
              <b>Gender</b>: ${this.gender},<br/>
              <b>Birthdate & Place</b>: ${this.birth},<br/>
              <b>Phone</b>: ${this.phone},<br/>
              <b>Email</b>: ${this.email}<br/>`,
            Attachments: [
              {
                name: `CV.${this.fileExtension}`,
                data: this.file,
              },
            ],
          }).then((val) => {
            Swal.fire({
              title: 'Error!',
              text: 'Do you want to continue',
              icon: 'error',
              confirmButtonText: 'Cool',
            });
            if (val !== 'OK') {
              this.disabled = false;
              this.loading = false;
              Swal.fire({
                confirmButtonColor: '#035795',
                text: 'Something is wrong with our server',
              });
            } else {
              this.disabled = false;
              this.loading = false;
              Swal.fire({
                confirmButtonColor: '#035795',
                text: 'Your application has been sent',
              });
              this.gender = '';
              this.name = '';
              this.phone = '';
              this.email = '';
              this.file = '';
              this.birth = '';
            }
          });
        }
      },
    },
  };
</script>

<style scoped>
  .btn-primary:hover,
  .btn-primary:focus,
  .btn-primary:active,
  .btn-primary.active {
    color: #285e8e;
    background-color: #fff;
    border: 2px solid #285e8e; /*set the color you want here*/
  }

  .swal2-styled.swal2-confirm {
    background-color: red !important;
  }

  .careers {
    overflow-x: hidden;
    font-family: Museo;
  }

  .career-section {
    margin-top: 30px;
    /* max-width: 1000px; */
    margin-left: auto;
    margin-right: auto;
  }

  .description {
    font-family: Museo;
    color: #035795;
    font-size: 15px;
  }

  .careers-description {
    max-width: 1000px;
    margin: auto;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  form {
    /* width: 80%; */
    color: rgb(126, 126, 126);
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 50px;
  }

  .form-group {
    margin-top: 20px;
  }

  .spin {
    float: right;
    margin-top: 30px;
  }

  .career-form {
    margin-bottom: 100px;
  }

  .form-control {
    border: 2px solid #035795 !important;
    border-radius: 10px;
  }

  .btn-primary {
    border-radius: 50px;
    background-color: #035795;
    border: 2px solid #035795;
    width: 200px;
    margin-top: 30px;
    float: right;
  }

  .email-career {
    color: #035795;
    font-size: 15px;
  }

  .email-attach {
    color: rgb(170, 170, 170);
    font-size: 15px;
  }

  input {
    height: 30px;
  }

  label {
    font-size: 15px;
    margin-top: 10px;
  }

  .warning {
    color: red;
  }

  .help-text {
    font-size: 12px;
    color: rgb(199, 199, 199);
  }

  @media only screen and (min-width: 768px) and (max-width: 993px) {
    .career-form {
      min-width: 100%;
    }

    .career-intro {
      min-width: 100%;
      text-align: center;
    }
  }
  @media only screen and (min-width: 993px) {
    .vl {
      position: relative;
      left: 70px;
      border-left: 2px solid #035795;
      height: 600px;
    }
    .career-form {
      min-width: 60%;
    }
    .short-input {
      min-width: 200px;
    }

    .long-input {
      min-width: 400px;
      height: 30px;
    }

    .career-section {
      max-width: 1000px;
    }

    .career-intro {
      margin-top: 70px;
    }
  }

  @media only screen and (max-width: 993px) {
    .vl {
      position: relative;
      border-top: 2px solid #035795;
    }
    .career-form {
      width: 100%;
    }
    .career-intro {
      min-width: 100%;
      text-align: center;
      padding-right: 20px;
      padding-left: 20px;
      margin-bottom: 50px;
    }
    .careers-description {
      text-align: center;
      padding-right: 20px;
      padding-left: 20px;
    }

    label {
      font-size: 10px;
    }

    .career-image {
      margin-top: 50px;
      margin-bottom: 50px;
    }

    .help-text {
      font-size: 8px;
    }
  }
</style>
its
