<template>
  <div class="partners text-center">
    <h2>Our Partners</h2>
    <!-- <img
      class="partner"
      v-for="(partner, i) in partners"
      :key="i"
      :src="partner.image"
    /> -->
    <img
      @click="openLink('https://fibrangroup.net/products/?lang=en')"
      class="partner"
      src="../assets/PartnerLogo/Edicas.jpg"
    />
    <img
      @click="openLink('https://www.viskase.com/')"
      class="partner"
      src="../assets/PartnerLogo/Viskase.jpg"
    />
    <img
      @click="openLink('http://www.waycheinfood.com/')"
      class="partner"
      src="../assets/PartnerLogo/WayChein.jpg"
    />
    <img
      @click="openLink('http://fuyung.com/')"
      class="partner-fuyung"
      src="../assets/PartnerLogo/Fuyung.png"
    />
    <img
      @click="
        openLink('https://en.angelyeast.com/products/yeast_extract/index.html')
      "
      class="partner"
      src="../assets/PartnerLogo/Angel.jpg"
    />
    <img
      @click="openLink('http://yeastock.com/about.html')"
      class="partner"
      src="../assets/PartnerLogo/yeastock.jpg"
    />
    <img
      @click="openLink('http://www.synthite.com/synthite/our-brands/Necol')"
      class="partner"
      src="../assets/PartnerLogo/Synthite.jpg"
    />
  </div>
</template>

<script>
  export default {
    name: 'Partners',
    data() {
      return {
        partners: [
          { image: require('../assets/PartnerLogo/Edicas.jpg') },
          { image: require('../assets/PartnerLogo/Viskase.jpg') },
          { image: require('../assets/PartnerLogo/WayChein.jpg') },
          { image: require('../assets/PartnerLogo/Fuyung.png') },
          { image: require('../assets/PartnerLogo/Angel.jpg') },
          { image: require('../assets/PartnerLogo/yeastock.jpg') },
          { image: require('../assets/PartnerLogo/Synthite.jpg') },
        ],
      };
    },
    methods: {
      openLink(link) {
        window.open(link);
      },
    },
  };
</script>

<style scoped>
  h2 {
    color: #035795;
    margin-bottom: 50px;
    font-size: 40px;
  }

  .partners {
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (max-width: 768px) {
    .partner {
      padding: 0px 20px 0px 20px;
      margin-bottom: 30px;
      width: 35%;
      height: auto;
    }

    .partner-fuyung {
      padding: 0px 20px 0px 20px;
      margin-bottom: 30px;
      width: 30%;
      height: auto;
    }

    h2 {
      font-size: 30px;
    }
  }
  @media only screen and (min-width: 768px) {
    .partner {
      padding: 0px 20px 0px 20px;
      margin: 0 1% 5% 1%;
      width: 200px;
      height: auto;
    }

    .partner-fuyung {
      padding: 0px 20px 0px 20px;
      margin-bottom: 10%;
      width: 150px;
      height: auto;
    }

    .partners {
      max-width: 1100px;
      margin-left: auto;
      margin-right: auto;
    }
  }
</style>
