<template>
  <button type="button" class="btn btn-primary">{{ buttonText }}</button>
</template>

<script>
  export default {
    name: 'Button',
    props: ['buttonText'],
  };
</script>

<style scoped>
  .btn-primary {
    border: 2px solid #035795;
    border-radius: 30px;
    background: transparent;
    color: #035795;
  }

  .btn-primary:hover,
  .btn-primary:focus,
  .btn-primary:active,
  .btn-primary.active,
  .open > .dropdown-toggle.btn-primary {
    color: #fff;
    background-color: #285e8e;
    border-color: #285e8e; /*set the color you want here*/
  }

  @media only screen and (min-width: 768px) {
    .btn-primary {
      width: 200px;
      height: 50px;
    }
  }
  @media only screen and (max-width: 768px) {
    .btn-primary {
      width: 200px;
      height: 40px;
      font-size: 15px;
    }
  }
</style>
