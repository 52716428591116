<template>
  <div class="slick-carousel">
    <VueSlickCarousel v-bind="settings" :arrows="true">
      <div
        v-for="(item, i) in products"
        :key="i"
        class="slick-img"
        :style="item.background"
      >
        <div class="slick-content">
          <div class="slick-text">
            <h1 class="slick-header">{{ item.title }}</h1>
            <p class="slick-desc">{{ item.description }}</p>
            <router-link :to="item.route">
              <button type="button" class="stacked-carousel-button">
                Learn more
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </VueSlickCarousel>
  </div>
</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel';
  import 'vue-slick-carousel/dist/vue-slick-carousel.css';

  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
  export default {
    name: 'Slick',
    components: { VueSlickCarousel },
    props: ['products'],
    data() {
      return {
        settings: {
          centerMode: true,
          centerPadding: '0px',
          focusOnSelect: true,
          infinite: true,
          slidesToShow: 3,
          speed: 700,
        },
      };
    },
  };
</script>

<style>
  .slick-carousel {
    overflow: hidden;
    transform: scale(1.4);
  }

  .slick-track {
    padding: 50px 0 50px 0;
  }

  .slick-dots {
    position: fixed;
    bottom: 40px;
  }
  .slick-dots li button:before {
    font-size: 15px;
  }

  .slick-dots li {
    margin: 0;
  }

  .stacked-carousel-button:hover {
    color: #fff;
    background-color: #285e8e;
    border: 1px solid white; /*set the color you want here*/
  }

  a {
    text-decoration: none;
  }

  .slick-dots li.slick-active button:before {
    color: #035795;
  }

  .slick-slide {
    transition: transform 0.3s;
  }

  .slick-slide.slick-center {
    transform: scale(1.3);
    z-index: 9;
    position: relative;
  }

  .slick-slide.slick-center .stacked-carousel-button {
    display: block;
  }

  .slick-img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .slick-content {
    width: 100%;
    height: 300px;
  }

  .slick-slider .slick-arrow::before {
    width: 85px !important;
    height: 85px !important;
  }

  .stacked-carousel-button {
    display: none;
    font-size: 10px;
    padding: 5px 10px;
    width: 100px;
    border: none;
    border-radius: 20px;
    color: #035795;
    box-shadow: 1px 2px 2px 0 grey;
  }

  .slick-next {
    right: 29.5vw;
  }

  .slick-prev {
    left: 27.5vw;
    z-index: 2;
  }

  .slick-prev::before,
  .slick-next::before {
    font-size: 4vw;
    opacity: 1;
    position: relative;
    top: -1.5vw;
  }

  @media only screen and (min-width: 1600px) {
    .slick-content {
      width: 100%;
      height: 20vw;
    }

    p {
      margin-bottom: 15px;
    }
    .slick-text {
      position: relative;
      top: 25%;
      left: -4%;
      width: 45%;
      float: right;
      color: white;
    }
    .slick-header {
      font-size: 1vw;
    }

    .slick-desc {
      font-size: 0.6vw;
      min-height: 60px;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1600px) {
    p {
      margin-bottom: 15px;
    }
    .slick-text {
      position: relative;
      top: 25%;
      left: -4%;
      width: 45%;
      float: right;
      color: white;
    }

    .slick-desc {
      min-height: 50px;
    }
    .slick-header {
      font-size: 1vw;
    }

    .slick-desc {
      font-size: 0.6vw;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    p {
      margin-bottom: 15px;
    }
    .slick-text {
      position: relative;
      top: 30%;
      left: -4%;
      width: 45%;
      float: right;
      color: white;
    }
    .slick-header {
      font-size: 1vw;
    }

    .slick-desc {
      font-size: 0.6vw;
      min-height: 25px;
    }

    .stacked-carousel-button {
      padding: 5px;
      font-size: 8px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {
    p {
      margin-bottom: 10px;
    }
    .slick-text {
      position: relative;
      top: 34%;
      left: -4%;
      width: 45%;
      float: right;
      color: white;
    }
    .slick-header {
      font-size: 1vw;
    }

    .slick-desc {
      font-size: 0.6vw;
      min-height: 20px;
    }

    .stacked-carousel-button {
      padding: 3px;
      width: 80px;
      font-size: 7px;
    }
  }
</style>
