<template>
  <div>
    <nav class="navbar navbar-expand-md navbar-light">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav mx-auto">
          <li class="nav-item dropdown">
            <router-link
              to="/products/naturalsolutions"
              class=" desktop-dropdown"
            >
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                aria-expanded="false"
              >
                Natural Solutions
              </a>
            </router-link>
            <a
              class="nav-link dropdown-toggle mobile-dropdown"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Natural Solutions
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li @click="changePage('naturalsolutions', 'meat-extracts')">
                <a class="dropdown-item">Meat Extract</a>
              </li>
              <hr class="dropdown-divider-desktop" />
              <li @click="changePage('naturalsolutions', 'flavor-ingredients')">
                <a class="dropdown-item">Flavor Ingredients</a>
              </li>
            </ul>
          </li>

          <li class="nav-item dropdown">
            <router-link
              to="/products/tastesolutions"
              class=" desktop-dropdown"
            >
              <a
                class="nav-link dropdown-toggle "
                id="navbarDropdown"
                role="button"
                aria-expanded="false"
              >
                Taste Solutions
              </a>
            </router-link>
            <a
              class="nav-link dropdown-toggle mobile-dropdown"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Taste Solutions
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li @click="changePage('tastesolutions', 'savoury-seasonings')">
                <a class="dropdown-item">Savoury Seasoning</a>
              </li>
              <hr class="dropdown-divider-desktop" />
              <li @click="changePage('tastesolutions', 'sweet-seasonings')">
                <a class="dropdown-item">Sweet Seasoning & Drink</a>
              </li>
              <hr class="dropdown-divider-desktop" />
              <li @click="changePage('tastesolutions', 'sambals')">
                <a class="dropdown-item">Sambals</a>
              </li>
              <hr class="dropdown-divider-desktop" />
              <li @click="changePage('tastesolutions', 'condiments')">
                <a class="dropdown-item">Sauce, Paste & Condiments</a>
              </li>
              <hr class="dropdown-divider-desktop" />
              <li @click="changePage('tastesolutions', 'beverage-solutions')">
                <a class="dropdown-item">Beverage Solution</a>
              </li>
            </ul>
          </li>

          <li class="nav-item dropdown">
            <router-link to="/products/menusolutions" class=" desktop-dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                aria-expanded="false"
              >
                Menu Solutions
              </a>
            </router-link>

            <a
              class="nav-link dropdown-toggle mobile-dropdown"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Menu Solutions
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li @click="changePage('menusolutions', 'ready-to-meal')">
                <a class="dropdown-item">Ready to Meal</a>
              </li>
            </ul>
          </li>

          <li class="nav-item dropdown">
            <router-link
              to="/products/tradingpartners"
              class=" desktop-dropdown"
            >
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                aria-expanded="false"
              >
                Trading Partners
              </a>
            </router-link>

            <a
              class="nav-link dropdown-toggle mobile-dropdown"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Trading Partners
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li @click="changePage('tradingpartners', 'casing')">
                <a class="dropdown-item">Casing</a>
              </li>
              <hr class="dropdown-divider-desktop" />
              <li @click="changePage('tradingpartners', 'flavor-enhancer')">
                <a class="dropdown-item">Flavor Enhancer</a>
              </li>
              <hr class="dropdown-divider-desktop" />
              <li @click="changePage('tradingpartners', 'natural-color')">
                <a class="dropdown-item">Natural Color</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
  export default {
    name: 'MidNavbar',
    methods: {
      changePage(params, section) {
        this.$router
          .push({
            name: 'product',
            params: { page: `${params}`, section: `${section}` },
          })
          .catch(() => {});

        this.$emit('changeSection', section);
      },
    },
  };
</script>

<style scoped>
  nav {
    background-color: #459ec7 !important;
    font-family: Museo;
  }

  .dropdown-divider-desktop {
    /* display: none; */
    height: 1px;
    color: #035795;
    opacity: 1;
  }

  .nav-link {
    color: white !important;
    margin: 20px 25px 20px 25px;
  }

  hr {
    margin: 1;
  }

  .dropdown-item {
    padding: 0 30px 0 30px;
    color: #035795;
  }

  .dropdown-item:hover {
    font-family: Museo-bold;
  }

  .dropdown-menu {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    top: 80px;
    min-width: 190px;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #035795;
    background-color: transparent;
    font-family: Museo-bold;
  }

  .navbar-nav {
    margin-right: 7%;
  }

  a {
    text-decoration: none !important;
  }

  .navbar-collapse {
    display: flex;
  }

  .navbar-toggler-icon {
    display: none;
  }

  .navbar-toggler {
    display: none;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-menu {
    /* position: absolute; */
    opacity: 1;
    background-color: rgb(236, 236, 236);
  }

  @media only screen and (min-width: 768px) {
    .navbar {
      padding-top: 0;
      padding-bottom: 0;
    }

    .dropdown:hover > .dropdown-menu {
      display: block;
    }

    .dropdown-menu.show {
      position: absolute;
      left: 20px;
      top: 5.3rem;
    }

    .mobile-dropdown {
      display: none !important;
    }
  }

  @media only screen and (max-width: 767px) {
    .navbar-nav {
      flex-direction: row;
      flex-wrap: wrap;
      text-align: center;
    }

    .navbar-nav .dropdown-menu {
      position: absolute;
    }

    .nav-item {
      flex: 0 0 50%;
      font-size: 3.5vw;
    }

    .dropdown-menu.show {
      /* position: absolute; */
      left: 8%;
      top: 3.5rem;
      /* min-width: 100px; */
    }

    .dropdown-menu {
      border-bottom-right-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      min-width: 165px;
    }

    .dropdown-item {
      padding: 0 0 0 1rem;
      font-size: 2.7vw;
      white-space: normal;
    }

    hr {
      margin: 0.5rem 0;
    }

    .desktop-dropdown {
      display: none !important;
    }
  }
</style>
