<template>
  <div>
    <div
      id="carouselExampleControls"
      class="carousel slide carousel-mobile"
      data-interval="false"
    >
      <div class="carousel-inner">
        <div
          v-for="item in carouselContents"
          :key="item.id"
          v-bind:class="{ active: item.active }"
          class="carousel-item"
        >
          <div class="carousel-content">
            <h3>{{ item.title }}</h3>
            <p>{{ item.description }}</p>
          </div>

          <img
            @click="showDetail(item.id)"
            :src="item.image"
            class="d-block w-100 carousel-image"
            alt="..."
          />
        </div>
        <ol class="carousel-indicators">
          <span
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            class="indicator active"
            aria-current="true"
            aria-label="Slide 1"
          ></span>
          <span
            class="indicator"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></span>
          <span
            class="indicator"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></span>
          <span
            class="indicator"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></span>
        </ol>
      </div>
      <a
        class="carousel-control-prev button-arrow"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="prev"
        ><span class="carousel-control-prev-icon" aria-hidden="true"></span
      ></a>
      <a
        class="carousel-control-next button-arrow"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="next"
        ><span class="carousel-control-next-icon" aria-hidden="true"></span
      ></a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NewsMobileBanner',
    props: ['content'],
    data() {
      return {
        carouselContents: [
          {
            id: '1',
            title: '10/10/2021',
            active: true,
            description:
              'PT Foodex Inti Ingredients is delighted to announce that we are settling into our new office!  Our Sales & Marketing office  is located in Kuningan near to',
            image: require('../assets/News/article9.jpg'),
          },
          {
            id: '2',
            title: '10/10/2021',
            active: false,
            description:
              'PT Foodex Inti Ingredients (FOODEX) is celebrating its 25th anniversary this year, and according to Ms Jenny Rusli, Business Development Director of...',
            image: require('../assets/News/article7.png'),
          },
          {
            id: '3',
            title: '10/10/2021',
            active: false,
            description:
              'Homegrown PT Foodex Inti Ingredients (FOODEX) is a leading seasonings and food ingredients manufacturer in Indonesia. Despite the challenges posed by the...',
            image: require('../assets/News/article8.png'),
          },
        ],
      };
    },
    methods: {
      showDetail(id) {
        this.$router.push({ path: `/news/${id}` });
      },
    },
  };
</script>

<style scoped>
  .carousel-mobile {
    position: relative;
    top: -100px;
  }

  .carousel-content {
    position: relative;
    top: 500px;
    margin: 0px 30px 0 30px;
    color: white;
    left: 0;
    text-align: left;
  }

  .btn-primary {
    background-color: white;
    color: #035795;
    border: none;
    padding: 8px 35px;
    border-radius: 30px;
  }

  .button-arrow {
    position: absolute;
    top: 100px;
  }

  .carousel-image {
    height: 600px;
    object-fit: cover;
  }

  .carousel-indicators .active {
    height: 10px;
    width: 10px;
    background-color: #ffffff;
    border-radius: 50%;
    display: inline-block;
  }

  .carousel-indicators {
    position: absolute !important;
    justify-content: flex-start !important;
    margin-left: 0% !important;
    padding-left: 30px;
    top: 88%;
  }

  .carousel-inner {
    position: relative;
  }

  .indicator {
    height: 10px;
    width: 10px !important;
    background-color: transparent;
    border-radius: 100%;
    border: 2px solid #ffffff;
    display: inline-block;
  }

  p {
    font-size: 15px;
  }
</style>
