<template>
  <div class="vision-mission row justify-content-center">
    <div class="content text-center col-md-6 col-lg-6 col-sm-12 col-sx-12">
      <img class="vm-image" src="../assets/Dashboard/vision-lamp.png" />
      <h2>Vision</h2>
      <p>
        To be the most preffered business partner for taste solutions &
        value-added food ingredients from Indonesia
      </p>
    </div>
    <div
      class="content mission text-center col-md-6 col-lg-6 col-sm-12 col-sx-12"
    >
      <img class="vm-image" src="../assets/Dashboard/mission-handshake.png" />
      <h2>Mission</h2>
      <p>
        To support customer product success with competitive taste solutions &
        value added food ingredients combined with superior service
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'VisionMission',
    components: {},
  };
</script>

<style scoped>
  .vision-mission {
    margin-top: 120px;
    height: 100%;
    background-color: #459ec7;
    padding: 20px 8% 0 8%;
  }

  img {
    width: 150px;
    height: 150px;
  }

  p {
    font-family: Museo-thin;
    font-size: 15px;
  }

  h2 {
    margin-top: 30px;
    font-family: Museo-bold;
    font-size: 40px;
  }

  .content {
    position: relative;
    top: -100px;
    color: white;
    margin: 0 70px 0 70px;
    width: 400px;
  }

  @media only screen and (max-width: 768px) {
    .mission {
      margin-top: 10px;
    }
    .content {
      padding: 50px;
      top: -150px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {
    .vision-mission {
      margin-top: 250px !important;
    }
  }
</style>
