<template>
  <div class="highlights">
    <div class="highlight-title text-center">
      <p class="highlight-news">News</p>
      <span>
        <img class="flower" src="../assets/Dashboard/flower.png" />
        <span class="highlight-title">Highlights</span>
        <img class="flower" src="../assets/Dashboard/flower.png" />
      </span>
    </div>
    <div class="row text-center highlight-content">
      <div
        class="highlight col-md-4 col-lg-4 col-sm-12 col-12 "
        v-for="(data, i) in highlightsData"
        :key="i"
      >
        <img @click="showDetail(data.id)" class="highlight-image" :src="require('../assets/News/' + data.mainImage)" />
        <div class="text-start highlight-text">
          <p class="date">{{ data.date }}</p>
          <h3>{{ data.title }}</h3>
          <p class="description">{{ sliceContent(data.content) }}</p>
        </div>
      </div>
    </div>
    <div class="highlight-read-more">
      <span><img src="../assets/Dashboard/border-line.png"/></span>
      <span><img src="../assets/Dashboard/border-line.png"/></span>
      <span><router-link to=/news>
        <Button :buttonText="'Read more'" />
      </router-link></span>
      <span><img src="../assets/Dashboard/border-line.png"/></span>
      <span><img src="../assets/Dashboard/border-line.png"/></span>
    </div>
  </div>
</template>

<script>
  import Button from '../components/Button.vue';
  import Articles from '../pages/Articles/articles.json';

  export default {
    name: 'Highlights',
    data() {
      return {
        highlightsData : this.spliceArticles()
      };
    },
    methods:{
      spliceArticles(){
        const cloneArticles = [ ...Articles]
        return cloneArticles.splice(0, Articles.length -6 )
      },
      sliceContent(content) {
        const sliceStr = content.slice(0, 200) + '...'
        return sliceStr.replace(/<\/?[^>]+(>|$)/g, "")
      },
      showDetail(id) {
        this.$router.push({ path: `/news/${id}` });
      },
    },
    components: { Button },
  };
</script>

<style scoped>
  h3 {
    font-size: 28px;
  }
  .date {
    font-size: 20px;
    color: rgb(170, 170, 170);
    font-family: Museo-thin;
  }

  .description {
    font-family: Museo-thin;
    font-size: 15px;
  }

  .highlights {
    margin-top: 50px;
    margin-bottom: 60px;
  }

  .highlight-content {
    margin-bottom: 50px;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 15px;
  }

  .highlight-image {
    border-radius: 20px;
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  .highlight-title {
    margin-bottom: 50px;
  }

  .highlight-text {
    margin-top: 20px;
    color: #035795;
  }

  .highlight-news {
    font-size: 25px;
    color: #d40833
  }

  .highlight-title {
    font-size: 40px;
    color: #035795;
  }

  .container-fluid {
    padding-right: 0 !important;
  }

  .flower {
    margin: 0 20px 0 20px;
    width: 40px;
  }

  .highlight-read-more {
    display: flex;
    justify-content: center;
  }

  button {
    padding: 7px;
    color: #035795;
    width: 200px;
    border-radius: 20px;
    border: 2px solid #035795;
    position: relative;
    top: -10px;
    background-color: white;
    margin: 0 10px 0 10px;
  }

  @media only screen and (min-width: 768px) and (max-width: 992px) {
    .highlight-image {
      height: 250px;
      width: 100%;
    }

    h3{
      font-size: 20px;
    }
  }
  @media only screen and (max-width: 767px) {
    .highlight-image {
      height: 300px;
    }

    .highlight-title {
      font-size: 30px
    }
  }
</style>
