<template>
  <div class="trading-partners">
    <div id="casing" class="main-title text-center">
      <div
        class="row justify-content-center align-items-center trading-title-mobile"
      >
        <img class="flower col-4" src="../../assets/Dashboard/flower.png" />
        <div class="ready-title col-3 text-center">
          Casing
        </div>
        <img class="flower col-4" src="../../assets/Dashboard/flower.png" />
      </div>
      <div class="col trading-title-desktop">
        <img class="flower" src="../../assets/Dashboard/flower.png" />
        <span class="ready-title">Casing</span>
        <img class="flower" src="../../assets/Dashboard/flower.png" />
      </div>
    </div>
    <div
      class="description container trading-content"
      v-for="c in casing"
      :key="c.title"
    >
      <div class="row justify-content-center align-items-center">
        <div class="col-md-6 col-12 text-center">
          <img class="logo" :src="c.image" />
        </div>
        <div class="logo-desc col-md-6 col-12">
          <p v-html="c.description"></p>
          <button
            class="learn-button btn btn-primary"
            @click="openLink(c.link)"
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
    <img class="mid-image" src="../../assets/Products/trading-parallax1.jpg" />
    <div class="parallax"></div>
    <div id="flavor-enhancer" class="trading-content title text-center">
      <div
        class="row justify-content-center align-items-center trading-title-mobile"
      >
        <img class="flower col-3" src="../../assets/Dashboard/flower.png" />
        <div class="ready-title col-7 text-center">
          Flavour Enhancer
        </div>
        <img class="flower col-3" src="../../assets/Dashboard/flower.png" />
      </div>
      <div class="col trading-title-desktop">
        <img class="flower" src="../../assets/Dashboard/flower.png" />
        <span class="ready-title">Flavour Enhancer</span>
        <img class="flower" src="../../assets/Dashboard/flower.png" />
      </div>
      <div class="description">
        <p>
          Flavor enhancer are compounds that are added to a food in order to
          supplement or enhance its own natural flavor.
        </p>
        <p>
          The concept of flavour enhancement originated in Asia, where cooks
          added seaweed to soup stocks in order to provide a richer flavour to
          certain foods. The flavour-enhancing component of seaweed was
          identified as the amino acid L-glutamate and monosodium gluatamade
          (MSG) became the first flavour enhancer to be used commercially. The
          rich flavour associated with L-glutamate was called umami.
        </p>
      </div>
    </div>

    <div
      class="description container trading-content"
      v-for="c in flavors"
      :key="c.title"
    >
      <div class="row align-items-center justify-content-center">
        <div class="col-md-6 col-12 text-center">
          <img v-if="c.logo" class="logo" :src="c.logo" />
          <img v-else-if="c.image" class="list-image" :src="c.image" />
        </div>
        <div class="col-md-6 col-12">
          <h3 class="list-title" v-if="c.title">{{ c.title }}</h3>
          <p v-html="c.description"></p>
          <button
            v-if="c.button"
            class="learn-button
            btn
            btn-primary"
            @click="openLink(c.link)"
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
    <div class="hl"></div>
    <div
      class="description container"
      v-for="c in flavorPartners"
      :key="c.title"
    >
      <div
        class="row justify-content-center align-items-center trading-content"
      >
        <div class="col-md-6 col-12 text-center">
          <img class="logo" :src="c.image" />
        </div>
        <div class="col-md-6 col-12">
          <p v-html="c.description"></p>
          <button
            @click="openLink(c.link)"
            class="learn-button btn btn-primary"
          >
            Learn More
          </button>
        </div>
      </div>
    </div>

    <div
      class="description container trading-content"
      v-for="c in yeasts"
      :key="c.title"
    >
      <div class="row align-items-center justify-content-center">
        <div class="col-md-6 col-12 text-center">
          <img v-if="c.logo" class="logo" :src="c.logo" />
          <img v-else-if="c.image" class="list-image" :src="c.image" />
        </div>
        <div class="col-md-6 col-12">
          <h3 class="list-title" v-if="c.title">{{ c.title }}</h3>
          <p v-html="c.description"></p>
          <button
            @click="openLink(c.link)"
            v-if="c.button"
            class="learn-button btn btn-primary"
          >
            Learn More
          </button>
        </div>
      </div>
    </div>

    <img class="mid-image" src="../../assets/Products/trading-parallax2.jpg" />
    <div class="parallax2"></div>

    <div id="natural-color" class=" title text-center trading-content">
      <div
        class="row justify-content-center align-items-center trading-title-mobile"
      >
        <img class="flower col-4" src="../../assets/Dashboard/flower.png" />
        <div class="ready-title col-6 text-center">
          Natural Color
        </div>
        <img class="flower col-4" src="../../assets/Dashboard/flower.png" />
      </div>
      <div class="col trading-title-desktop">
        <img class="flower" src="../../assets/Dashboard/flower.png" />
        <span class="ready-title">Natural Color</span>
        <img class="flower" src="../../assets/Dashboard/flower.png" />
      </div>
      <div class="description">
        <p>
          Natural food colours originate from a wide range of sources like
          vegetables, fruits, plants, minerals and other edible natural sources.
          They impart colour when added to food or drink.
        </p>
        <p>
          Natural Food colours are preparations obtained from foods and other
          edible natural source materials obtained by physical and/or chemical
          extraction resulting in a selective extraction of the pigments
          relative to the nutritive or aromatic constituents.
        </p>
      </div>
      <div class="description container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-6 col-12">
            <img
              class="logo-symega"
              src="../../assets/PartnerLogo/Symega.png"
            />
          </div>
          <div class="col-md-6 col-12 logo-desc">
            <p>
              Symega's expertise in natural food colouring solutions is backed
              by its extensive knowledge and understanding of dealing with plant
              pigments and extracts. Sourced from the finest raw materials, our
              natural colours are suitable for use in a wide range of
              applications, including baked goodsm beverages, dairy, snacks
              confectionery, meat processing.
            </p>
            <button
              @click="openLink('https://symega.com/')"
              class="learn-button btn btn-primary"
            >
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
    <img class="mid-image" src="../../assets/Products/trading-parallax3.jpg" />
    <div class="parallax3"></div>
  </div>
</template>

<script>
  export default {
    name: 'NaturalSolutions',
    props: ['subsection'],
    watch: {
      subsection(section) {
        if (section) {
          var elem = document.getElementById(section);
          elem.scrollIntoView();
        }
        this.$emit('clearSection');
      },
    },
    mounted() {
      if (this.$route.params.section) {
        var elem = document.getElementById(this.$route.params.section);
        elem.scrollIntoView();
      } else {
        document.body.scrollTop += 10;
      }
      this.$emit('clearSection');
    },
    methods: {
      openLink(link) {
        window.open(link);
      },
    },
    data() {
      return {
        casing: [
          {
            description: `<p>
              Viskase is committed to delivering a full range of best-in-class food packaging solutions and services, maintainin
              a long history of global market leadership, and providing the highest value to our customers
            </p>`,
            image: require('../../assets/PartnerLogo/Viskase.jpg'),
            link: 'https://www.viskase.com/',
          },
          {
            description: `<p>
              Fibran Group produces and distributes natural collagen casing for all types of cured, fresh, cooked and smoked sausages, in
              different calibers depending on the needs of the client.
            </p>
            <p>
              It has a wide range of products divided into two large families. for direct consumption and for indirect consumption.
            </p>
            `,
            image: require('../../assets/PartnerLogo/Edicas.jpg'),
            link: 'https://fibrangroup.net/products/?lang=en',
          },
        ],
        flavorPartners: [
          {
            description: `<p>
              Angel Yeast Co. Ltd was founded in 1986. As a listed high-tech yeast company in China, it specializes in the production of yeast and yeast derivatives.
              Angel Yeast has 10 international advanced production bases in China, Egypt and Russia
            </p>`,
            image: require('../../assets/PartnerLogo/Angel.jpg'),
            link: 'https://en.angelyeast.com/products/yeast_extract/index.html',
          },
          {
            description: `<p>
              Yeastock series are innovative yeast extracts for seasoning developed by Asahi Group, the oldest and biggest yeast extract manufacturer
              in Japan as a member of Asahi Group produces Yeastock products.
            </p>
            <p>
              To promote food safety and a healthy lifestyle, we offer customers natural savory ingredients based on the Asahi Group's core technologies.
            </p>
            `,
            image: require('../../assets/PartnerLogo/yeastock.jpg'),
            link: 'http://yeastock.com/about.html',
          },
        ],
        flavors: [
          {
            description: `<p>
              WAY CHEIN has becoming the top HVOP producer in China of 21.000 MT production capacity with adequate certificates such as ISO9001, ISO22000, HACCP, CNAS, KOSHER, HALAL, SEDEX, and BRC
            </p>`,
            button: true,
            logo: require('../../assets/PartnerLogo/WayChein.jpg'),
            link: 'http://en.waycheinfood.com/',
          },
          {
            title: 'HVP (Hydrolized Vegetable Protein)',
            description: `<p>
              Hydrolized Vegetable protein (HVP) is a flavour enhancer used in processed foods. It is usually produced by acid hydrolysis of soybean, corn, or what, which are broken down into
              amino acids. HVP is also produced by enzyme hydrolysis or by alkaline treatment.
            </p>
            `,
            image: require('../../assets/Products/hvp.png'),
          },
          {
            title: 'Soy Sauce Powder',
            description: `<p>
              Soy sauce is usually considered an Asian condiment and is made from fermented soybeans and roasted ground wheat. Soy sauce powder is perfect for dry rubs, seasonings, and marinaeds, and for
              simply sprinkling on items for flavor enhancement.
            </p>
            `,
            image: require('../../assets/Products/soySauce.png'),
          },
        ],
        yeasts: [
          {
            title: 'Yeast Extract',
            description: `
            <p>Yeast Extracts are used as food additives or flavorings, or as nutrients for bacterial culture media. They are often used to create savory flavors and umami taste sensations,
              and can be found in a large variety of packaged food, including frozen meals, crackers, snack foods, gravy, stock and more.</p>`,
            image: require('../../assets/Products/yeast.png'),
          },
        ],
        naturalColor: [
          {
            description: `
            <p>Sysmega's expertise in natural food colouring solutions is backed by its extensive knowledge and understanding of dealing with plant pigments and extracts. Sourced from the finest raw materials, our natural colours are suitable
            for use in a wide range of applications, including baked goods, beverages, dairy, snacks, confectionery, meat processing</p>`,
            image: require('../../assets/Products/yeast.png'),
          },
        ],
      };
    },
  };
</script>

<style scoped>
  #casing {
    scroll-margin-top: 7em;
  }

  #flavor-enhancer {
    scroll-margin-top: 7em;
  }

  #natural-color {
    scroll-margin-top: 7em;
  }

  .btn-primary:hover,
  .btn-primary:focus,
  .btn-primary:active,
  .btn-primary.active {
    color: #285e8e;
    background-color: #fff;
    border: 2px solid #285e8e; /*set the color you want here*/
  }

  .trading-partners {
    font-family: Museo-thin;
  }
  .ready-title {
    color: #035795;
    font-family: Museo-bold;
  }

  .mid-image {
    width: 100%;
  }

  .list-image {
    height: 170px;
  }

  .btn-primary {
    background-color: #035795;
    border-radius: 30px;
    border: 2px solid #035795;
    padding: 5px 40px;
  }

  .hl {
    border-top: 2px solid #035795;
    width: 80%;
    margin: auto;
  }

  .learn-button {
    margin-top: 5px;
  }
  @media only screen and (min-width: 768px) {
    .ready-title {
      font-size: 40px;
    }

    .trading-content {
      margin-left: auto;
      margin-right: auto;
      padding-left: 30px;
      padding-right: 30px;
      max-width: 1100px;
    }

    .parallax {
      background-image: url('../../assets/Products/trading-parallax1.jpg');
      min-height: 400px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      background-attachment: fixed;
      display: block;
    }

    .parallax2 {
      background-image: url('../../assets/Products/trading-parallax2.jpg');
      min-height: 400px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      background-attachment: fixed;
      display: block;
    }

    .parallax3 {
      background-image: url('../../assets/Products/trading-parallax3.jpg');
      min-height: 400px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      background-attachment: fixed;
      display: block;
    }

    .flower {
      position: relative;
      top: -10px;
      margin: 0 30px 0 30px;
      width: 40px;
      height: 100%;
    }
    .trading-title-mobile {
      display: none;
    }

    .trading-title-desktop {
      margin-top: 30px;
    }

    .description {
      /* margin: auto; */
      margin-top: 50px;
      margin-bottom: 50px;
      width: 100%;
      color: #035795;
      font-size: 15px;
    }
    .mid-image {
      display: none;
    }

    .logo {
      height: 70px;
      margin-bottom: 20px;
    }

    .logo-symega {
      height: 50px;
      margin-bottom: 20px;
    }

    .logo-desc {
      text-align: start;
    }
  }
  @media only screen and (max-width: 767px) {
    .main-title {
      margin-top: 30px;
    }
    .parallax {
      display: none;
    }

    .parallax2 {
      display: none;
    }
    .parallax3 {
      display: none;
    }

    .logo-desc {
      margin-top: 30px;
      text-align: center;
    }

    .description {
      margin-top: 30px;
      margin-bottom: 30px;
      padding: 20px;
      width: 100%;
      color: #035795;
      text-align: center;
    }

    .flower {
      width: 60px;
      height: 100%;
    }
    .trading-title-desktop {
      display: none;
    }
    .logo {
      height: 60px;
      margin-bottom: 20px;
    }

    .logo-symega {
      height: 40px;
      margin-bottom: 10px;
    }

    .ready-title {
      font-size: 25px;
    }

    .trading-title-mobile {
      margin-top: 50px;
      /* margin-bottom: 10px; */
    }

    .list-title {
      margin-top: 30px;
      margin-bottom: 20px;
      font-family: Museo-bold;
    }
  }
</style>
