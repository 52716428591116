<template>
  <div class="article">
    <Navbar />
    <Jumbotron
      :backgroundImg="enrichBackgroundImg(articles[currentParams].mainImage)"
      :mobileImage="enrichBackgroundImg(articles[currentParams].mainImage)"
      :mobileMiddle="true"
      :title="''"
      :position="'middle'"
      :description="''"
    />
    <div class="article-main">
      <h3>{{ articles[currentParams].date }}</h3>
      <h2>{{ articles[currentParams].title }}</h2>
      <p class="article-content" v-html="articles[currentParams].content"></p>
      <img
        class="other-image"
        v-for="(item, index) in articles[currentParams].otherImages"
        :key="index"
        :src="require('../assets/News/' + item)"
      />
    </div>
    <Footer />
  </div>
</template>
<script>
  import Navbar from '../components/Navbar.vue';
  import Jumbotron from '../components/Jumbotron.vue';
  import Articles from '../pages/Articles/articles.json';
  import Footer from '../components/Footer.vue';

  export default {
    name: 'News',
    components: { Jumbotron, Navbar, Footer },
    data() {
      return {
        articles: Articles,
        currentParams: this.$router.history.current.params.id - 1,
        // mobileBackground: require('../assets/Dashboard/highlight1.png'),
      };
    },
    methods: {
      enrichBackgroundImg(img) {
        return {
          backgroundImage: `url(${require(`../assets/News/${img}`)})`,
        };
      },
    },
  };
</script>
<style scoped>
  .article {
    font-family: Museo;
    color: #035795;
  }

  h2 {
    margin-bottom: 40px;
  }

  h3 {
    font-family: Museo-thin;
  }

  .article-main {
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 5%;
    max-width: 1100px;
  }
  .article-content {
    color: black;
    font-family: Museo-thin;
    margin-right: 30px;
  }

  .other-image {
    margin-top: 30px;
    margin-right: 20px;
    width: 40%;
    height: 100%;
    object-fit: cover;
  }

  @media only screen and (max-width: 767px) {
    .article-main {
      margin-top: 50px;
      margin-bottom: 50px;
      margin-left: auto !important;
      margin-right: auto !important;
      padding: 20px;
      max-width: 1100px;
    }

    .other-image {
      margin-top: 30px;
      margin-right: 20px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
</style>
